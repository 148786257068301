import React, {FunctionComponent, ReactElement} from 'react'
import {createStyles, Snackbar, withStyles, WithStyles} from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme =>
  createStyles({
    close: {
      padding: theme.spacing.unit / 2,
    },
  })

type Props = WithStyles<typeof styles> & {
  onClose: any,
  snackbar: {
    message: string,
    timeout: number,
    action?: ReactElement<{}>,
  } | null,
}

const Component: FunctionComponent<Props> = ({classes, ...props}) => props.snackbar ? (
  <Snackbar
    open={true}
    onClose={props.onClose}
    message={props.snackbar.message}
    autoHideDuration={props.snackbar.timeout}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    ClickAwayListenerProps={props.snackbar.action ? { onClickAway: () => {} } : undefined}
    action={[
      props.snackbar.action,
      <IconButton
        key="close-icon"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
) : null

export default withStyles(styles)(Component)
