import React, {FunctionComponent} from 'react'
import {Match, navigate} from '@reach/router'
import {ListItem} from '@material-ui/core'
import {ListItemProps} from '@material-ui/core/ListItem'

type Props = ListItemProps & {
  to: string
  disabled?: boolean
}

const ListItemLink: FunctionComponent<Props> = ({
  to,
  disabled,
  children,
  ...props
}) => (
  <Match path={to}>
    {({match}) => (
      <ListItem
        selected={!!match}
        onClick={() => navigate(to)}
        disabled={disabled || false}
        button={true}
        {...props}
      >
        {children}
      </ListItem>
    )}
  </Match>
)

export default ListItemLink
