import React, {FunctionComponent, useEffect} from 'react'
import {RouteComponentProps} from '@reach/router'
import {connect} from 'react-redux'
import {actions as shopActions} from '../../features/shops/actions'
import {RootState} from '../../features/root-reducer'
import {getSelectedShop} from '../../features/shops/selectors'
import {Shop} from '../../api/definitions/types/shop'

type Props = RouteComponentProps<{shopId: string}> & {
  selectShop: typeof shopActions.selectShop
  selectedShop: Shop | null
}

const ShopSelector: FunctionComponent<Props> = ({
  shopId,
  selectShop,
  selectedShop,
  children,
}) => {
  useEffect(() => {
    selectShop(shopId)
  }, [shopId])

  return selectedShop ? children : null
}

const mapStateToProps = (state: RootState) => ({
  selectedShop: getSelectedShop(state),
})

const mapDispatchToProps = {
  selectShop: shopActions.selectShop,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopSelector)
