import React, {FunctionComponent} from 'react'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core'
import {Field} from '../../../../../shared/components/forms/Field'
import MagentoSalesRulesField from '../../../../../shops/components/MagentoSalesRulesField'
import {Shop} from '../../../../../../api/definitions/types/shop'

type Props = {
  selectedShop: Shop | null
}

const MagentoFields: FunctionComponent<Props> = ({selectedShop}) => (
  <Grid container spacing={24}>
    <Grid item md={12} xs={12}>
      <MagentoSalesRulesField
        name="couponConfiguration.ruleId"
        shopId={selectedShop ? selectedShop.id : ''}
      />
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.codePattern" defaultValue="HELLO">
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <InputLabel htmlFor={input.name}>Code prefix</InputLabel>
            <Input {...input} />

            {input.value && (
              <FormHelperText>
                Sample code: {input.value}-A2FJSKDI9
              </FormHelperText>
            )}
            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field
        name="couponConfiguration.expirationTime"
        defaultValue={2592000} // 30 days
        formatter={{
          toValue: value => value * 86400,
          toDisplay: value => (value ? Math.floor(value / 86400) : 0),
        }}
      >
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <InputLabel htmlFor={input.name}>Validity time</InputLabel>
            <Input
              type="number"
              endAdornment={
                <InputAdornment position="end">days</InputAdornment>
              }
              {...input}
            />

            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>
  </Grid>
)

export default MagentoFields
