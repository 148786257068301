import {createSelector} from 'reselect'
import {RootState} from '../root-reducer'

const clientLoading = (state: RootState) => state.auth.client.loading
const shopsLoading = (state: RootState) =>
  state.shops.loading && state.shops.items.length === 0
const integrationsLoading = (state: RootState) => state.integrations.loading
const automationsLoading = (state: RootState) =>
  state.automations.collection.loading

export const appLoading = createSelector(
  clientLoading,
  shopsLoading,
  integrationsLoading,
  automationsLoading,
  (clientLoading, shopsLoading, integrationsLoading, automationsLoading) =>
    clientLoading || shopsLoading || integrationsLoading || automationsLoading,
)
