import React from 'react';
import {connect} from "react-redux";
import {RouteComponentProps, Redirect} from '@reach/router';

import {actions as authActions} from "../../../features/auth/actions";
import Layout from "./Layout";

const mapDispatchToProps = ({
  handleLogoutClick: authActions.logout,
});

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
