import React, {FunctionComponent} from 'react';
import {connect} from "react-redux";
import {Token} from "../api/definitions/types/token";
import {RootState} from "../features/root-reducer";
import PageLoader from "../features/shared/components/PageLoader";

import AnonymousApp from './AnonymousApp/App';
import LimitedClientApp from './LimitedClientApp/App';
import FullClientApp from './FullClientApp/App';
import {appLoading} from "../features/shared/selectors";

type Props = {
  token: Token | null;
  appLoading: boolean;
  hasAtLeastOneShop: boolean;
};

const App: FunctionComponent<Props> = ({
  token,
  appLoading,
  hasAtLeastOneShop,
}) => {
  if (token === null) {
    return <AnonymousApp />;
  }

  if (appLoading) {
    return <PageLoader />;
  }

  if (!hasAtLeastOneShop) {
    return <LimitedClientApp />
  }

  return <FullClientApp />;
}

const mapStateToProps = (state: RootState) => ({
  token: state.auth.token,
  appLoading: appLoading(state),
  hasAtLeastOneShop: state.shops.items.length > 0,
});

export default connect(mapStateToProps)(App);
