import React, {FunctionComponent} from 'react'
import {
  Button,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {
  renderProblem,
  hasProblem,
} from '../../../shared/components/forms/FieldProblem'
import {Problem} from '../../../../api/definitions/responses/problem'

type Props = WithStyles<typeof styles> & {
  handleSubmit: any
  isSubmitting: boolean
  problem: Problem | null
}

const RegisterForm: FunctionComponent<Props> = ({
  handleSubmit,
  isSubmitting,
  problem,
  classes,
}) => (
  <form className={classes.form} onSubmit={handleSubmit}>
    <FormControl
      margin="normal"
      required
      fullWidth
      error={hasProblem(problem, 'firstName')}
    >
      <InputLabel htmlFor="firstName">First name</InputLabel>
      <Input id="firstName" name="firstName" autoFocus={true} />
      {renderProblem(problem, 'firstName')}
    </FormControl>

    <FormControl
      margin="normal"
      required
      fullWidth
      error={hasProblem(problem, 'email')}
    >
      <InputLabel htmlFor="email">Email Address</InputLabel>
      <Input id="email" name="email" autoComplete="email" />
      {renderProblem(problem, 'email')}
    </FormControl>

    <FormControl
      margin="normal"
      required
      fullWidth
      error={hasProblem(problem, 'password')}
    >
      <InputLabel htmlFor="password">Password</InputLabel>
      <Input
        name="password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      {renderProblem(problem, 'password')}
    </FormControl>

    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={isSubmitting}
    >
      Start for free
    </Button>
  </form>
)

const styles = theme =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    icon: {
      fontSize: 25,
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    snackbar: {
      backgroundColor: theme.palette.error.dark,
    },
  })

export default withStyles(styles)(RegisterForm)
