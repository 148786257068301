import {from} from 'rxjs'
import {combineEpics, Epic} from 'redux-observable'
import {
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators'
import {getType, isOfType} from 'typesafe-actions'
import {addDays, addMonths} from 'date-fns'
import * as deepEqual from 'deep-equal'

import {RootState} from '../root-reducer'
import {RootAction, RootAction as Action} from '../root-action'
import {actions as sharedActions} from '../shared/actions'
import {actions} from './actions'
import {
  createToken,
  loadClient,
  createClient,
  updateSubscriptionPlan,
  cancelSubscription,
  loadClientUsage,
  requestPassword,
  resetPassword,
} from '../../api/clients'
import {isProblem} from '../../api/models'

const initializeAppEpic: Epic<Action, Action, RootState> = (
  action$,
  store,
  {jwtDecode},
) =>
  action$.pipe(
    filter(isOfType(getType(sharedActions.initializeApp))),
    filter(() => store.value.auth.token !== null),
    map(() => jwtDecode(store.value.auth.token).id),
    map(id => actions.loadClient({id})),
  )

const createTokenEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.submitLoginForm))),
    switchMap(action =>
      from(createToken(store.value.auth.token)(action.payload)).pipe(
        map(response =>
          isProblem(response)
            ? actions.loginFailure({problem: response})
            : actions.loginSuccess({
                ...response,
                rememberMe: action.payload.rememberMe,
              }),
        ),
      ),
    ),
  )

const storeTokenEpic: Epic<Action, Action, RootState> = (
  action$,
  store,
  {cookies},
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loginSuccess))),
    tap(action =>
      cookies.set('token', action.payload.token, {
        path: '/',
        expires: action.payload.rememberMe
          ? addMonths(new Date(), 3)
          : addDays(new Date(), 1),
      }),
    ),
    ignoreElements(),
  )

const deleteTokenEpic: Epic<Action, Action, RootState> = (
  action$,
  store,
  {cookies},
) =>
  action$.pipe(
    filter(
      isOfType([getType(actions.logout), getType(actions.loadClientFailure)]),
    ),
    tap(() => cookies.remove('token', {path: '/'})),
    ignoreElements(),
  )

const loadClientEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadClient))),
    switchMap(action => loadClient(store.value.auth.token)(action.payload.id)),
    map(response =>
      isProblem(response)
        ? actions.loadClientFailure()
        : actions.loadClientSuccess(response),
    ),
  )

const loadClientUsageEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadClientUsage))),
    switchMap(action =>
      from(loadClientUsage(store.value.auth.token)(action.payload.id)).pipe(
        map(response =>
          isProblem(response)
            ? actions.loadClientUsageFailure({
                id: action.payload.id,
                data: response,
              })
            : actions.loadClientUsageSuccess({
                id: action.payload.id,
                data: response,
              }),
        ),
      ),
    ),
  )

const createSubscriptionEpic: Epic<Action, Action, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.createSubscription))),
    throttleTime(1000, undefined, {leading: true, trailing: true}),
    switchMap(action =>
      from(loadClient(store.value.auth.token)(action.payload.id)).pipe(
        map(response => {
          if (isProblem(response)) {
            return actions.createSubscriptionFailure(response)
          }

          if (deepEqual(response, store.value.auth.client.item)) {
            return actions.createSubscription(action.payload)
          }

          return actions.createSubscriptionSuccess(response)
        }),
      ),
    ),
  )

const updateSubscriptionPlanEpic: Epic<Action, Action, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.updateSubscription))),
    switchMap(({payload}) =>
      from(
        updateSubscriptionPlan(store.value.auth.token)(
          payload.id,
          payload.data,
        ),
      ).pipe(
        map(response =>
          isProblem(response)
            ? actions.updateSubscriptionFailure({
                id: payload.id,
                data: response,
              })
            : actions.updateSubscriptionSuccess({
                id: payload.id,
                data: response,
              }),
        ),
      ),
    ),
  )

const cancelSubscriptionEpic: Epic<Action, Action, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.cancelSubscription))),
    switchMap(({payload}) =>
      from(cancelSubscription(store.value.auth.token)(payload.id)).pipe(
        map(response =>
          isProblem(response)
            ? actions.cancelSubscriptionFailure({
                id: payload.id,
                data: response,
              })
            : actions.cancelSubscriptionSuccess({
                id: payload.id,
                data: response,
              }),
        ),
      ),
    ),
  )

const registerClientEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.submitRegisterForm))),
    switchMap(action => createClient(store.value.auth.token)(action.payload)),
    map(response =>
      isProblem(response)
        ? actions.registerFailure({problem: response})
        : actions.registerSuccess(response),
    ),
  )

const requestPasswordEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.submitRequestPasswordForm))),
    switchMap(action =>
      requestPassword(store.value.auth.token)(action.payload),
    ),
    map(response =>
      isProblem(response)
        ? actions.requestPasswordFailure({problem: response})
        : actions.requestPasswordSuccess(response),
    ),
  )

const requestPasswordSuccessEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state,
  {navigate},
) =>
  action$.pipe(
    filter(isOfType(getType(actions.requestPasswordSuccess))),
    tap(() => navigate(`/request-password-success`)),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Email sent with success.',
        timeout: 15000,
      }),
    ),
  )

const resetPasswordEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.submitResetPasswordForm))),
    switchMap(({payload: {clientId, code, data}}) =>
      resetPassword(store.value.auth.token)(clientId, code, data),
    ),
    map(response =>
      isProblem(response)
        ? actions.resetPasswordFailure({problem: response})
        : actions.resetPasswordSuccess(response),
    ),
  )

const resetPasswordSuccessEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state,
  {navigate},
) =>
  action$.pipe(
    filter(isOfType(getType(actions.resetPasswordSuccess))),
    tap(() => navigate(`/login`)),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Password updated with success.',
        timeout: 15000,
      }),
    ),
  )

const mapToLoginSuccessEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isOfType(getType(actions.registerSuccess))),
    map(action => actions.loginSuccess({...action.payload, rememberMe: true})),
  )

export const epics = combineEpics(
  initializeAppEpic,
  createTokenEpic,
  storeTokenEpic,
  deleteTokenEpic,
  loadClientEpic,
  loadClientUsageEpic,
  createSubscriptionEpic,
  updateSubscriptionPlanEpic,
  cancelSubscriptionEpic,
  registerClientEpic,
  requestPasswordEpic,
  requestPasswordSuccessEpic,
  resetPasswordEpic,
  resetPasswordSuccessEpic,
  mapToLoginSuccessEpic,
)
