import React from 'react'
import {connect} from 'react-redux'
import {navigate} from '@reach/router'
import {actions} from '../../../actions'
import {RootState} from '../../../../root-reducer'
import ConnectMagentoForm from './ConnectMagentoForm'
import {getSelectedShop} from '../../../selectors'

const mapStateToProps = (state: RootState) => ({
  problem: state.shops.magentoForm.problem,
  submitting: state.shops.magentoForm.submitting,
})

const mapDispatchToProps = {
  connectMagento: actions.connectMagento,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectMagentoForm)
