import React, {FunctionComponent, Fragment} from 'react'
import WooCommerceFields from './WooCommerceFields'
import MagentoFields from './MagentoFields'
import {ConditionField} from '../../../../shared/components/forms/ConditionField'

const CouponConfigurationFields: FunctionComponent = () => (
  <Fragment>
    <ConditionField when="couponConfiguration.type" is="woo_commerce">
      <WooCommerceFields />
    </ConditionField>

    <ConditionField when="couponConfiguration.type" is="magento">
      <MagentoFields />
    </ConditionField>
  </Fragment>
)

export default CouponConfigurationFields
