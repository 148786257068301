import {from} from 'rxjs'
import {combineEpics, Epic} from 'redux-observable'
import {filter, map, switchMap} from 'rxjs/operators'

import {getType, isOfType} from 'typesafe-actions'
import {RootState} from '../root-reducer'
import {RootAction} from '../root-action'
import {actions} from './actions'
import {
  createMailchimpIntegration,
  loadIntegrations,
  loadMailchimpListCollection,
  loadMailchimpMergeFieldCollection,
} from '../../api/clients'
import {isProblem} from '../../api/models'
import {actions as sharedActions} from '../shared/actions'
import {actions as authActions} from '../auth/actions'

const initializeIntegrationsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(
      isOfType([
        getType(sharedActions.initializeApp),
        getType(authActions.loginSuccess),
      ]),
    ),
    filter(() => store.value.auth.token !== null),
    map(() => actions.loadIntegrations()),
  )

const loadIntegrationsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadIntegrations))),
    switchMap(() => loadIntegrations(store.value.auth.token)()),
    map(response =>
      isProblem(response)
        ? actions.loadIntegrationsFailure(response)
        : actions.loadIntegrationsSuccess(response),
    ),
  )

const loadMailchimpListsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadMailchimpLists))),
    switchMap(({payload: {mailchimpId}}) =>
      from(
        loadMailchimpListCollection(store.value.auth.token)(mailchimpId),
      ).pipe(
        map(response =>
          isProblem(response)
            ? actions.loadMailchimpListsFailure({
                mailchimpId,
                problem: response,
              })
            : actions.loadMailchimpListsSuccess({mailchimpId, lists: response}),
        ),
      ),
    ),
  )

const loadMailchimpMergeFieldsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadMailchimpMergeFields))),
    switchMap(({payload: {mailchimpId, listId}}) =>
      from(
        loadMailchimpMergeFieldCollection(store.value.auth.token)(
          mailchimpId,
          listId,
        ),
      ).pipe(
        map(response =>
          isProblem(response)
            ? actions.loadMailchimpMergeFieldsFailure({
                mailchimpId,
                listId,
                problem: response,
              })
            : actions.loadMailchimpMergeFieldsSuccess({
                mailchimpId,
                listId,
                mergeFields: response,
              }),
        ),
      ),
    ),
  )

const createMailchimpIntegrationEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, store) =>
  action$.pipe(
    filter(isOfType(getType(actions.createMailchimpIntegration))),
    switchMap(action =>
      createMailchimpIntegration(store.value.auth.token)(action.payload),
    ),
    map(response =>
      isProblem(response)
        ? actions.createMailchimpIntegrationFailure(response)
        : actions.createMailchimpIntegrationSuccess(response),
    ),
  )

const notifyMailchimpIntegrationCreatedEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isOfType(getType(actions.createMailchimpIntegrationSuccess))),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Mailchimp account connected with success.',
        timeout: 7000,
      }),
    ),
  )

export const epics = combineEpics(
  initializeIntegrationsEpic,
  loadIntegrationsEpic,
  loadMailchimpListsEpic,
  loadMailchimpMergeFieldsEpic,
  createMailchimpIntegrationEpic,
  notifyMailchimpIntegrationCreatedEpic,
)
