import React from 'react'
import {from} from 'rxjs'
import {filter, ignoreElements, map, switchMap, tap} from 'rxjs/operators'
import {combineEpics, Epic} from 'redux-observable'
import {getType, isOfType} from 'typesafe-actions'

import {RootState} from '../root-reducer'
import {RootAction} from '../root-action'
import {actions} from './actions'
import {
  createAutomation,
  deleteAutomation,
  loadAutomation,
  loadAutomationCollection,
  updateAutomation,
} from '../../api/clients'
import {isProblem} from '../../api/models'
import {actions as sharedActions} from '../shared/actions'
import {actions as authActions} from '../auth/actions'
import {Button} from '@material-ui/core'

const initializeEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(
      isOfType([
        getType(sharedActions.initializeApp),
        getType(authActions.loginSuccess),
      ]),
    ),
    filter(() => store.value.auth.token !== null),
    map(() => actions.loadAutomationCollection()),
  )

const loadAutomationCollectionEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadAutomationCollection))),
    switchMap(() => loadAutomationCollection(store.value.auth.token)()),
    map(response =>
      !isProblem(response)
        ? actions.loadAutomationCollectionSuccess(response)
        : actions.loadAutomationCollectionFailure(response),
    ),
  )

const loadAutomationEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadAutomation))),
    switchMap(({payload}) =>
      from(loadAutomation(store.value.auth.token)(payload.id)).pipe(
        map(response =>
          isProblem(response)
            ? actions.loadAutomationFailure({id: payload.id, response})
            : actions.loadAutomationSuccess({id: payload.id, response}),
        ),
      ),
    ),
  )

const createAutomationEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.createAutomation))),
    switchMap(action =>
      createAutomation(store.value.auth.token)(action.payload),
    ),
    map(response =>
      isProblem(response)
        ? actions.createAutomationFailure(response)
        : actions.createAutomationSuccess(response),
    ),
  )

const updateAutomationEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.updateAutomation))),
    switchMap(({payload}) =>
      from(
        updateAutomation(store.value.auth.token)(payload.id, payload.data),
      ).pipe(
        map(response =>
          isProblem(response)
            ? actions.updateAutomationFailure({id: payload.id, response})
            : actions.updateAutomationSuccess({id: payload.id, response}),
        ),
      ),
    ),
  )

const deleteAutomationEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.deleteAutomation))),
    switchMap(({payload}) =>
      from(deleteAutomation(store.value.auth.token)(payload.id)).pipe(
        map(response =>
          isProblem(response)
            ? actions.deleteAutomationFailure({id: payload.id})
            : actions.deleteAutomationSuccess({id: payload.id}),
        ),
      ),
    ),
  )

const redirectToAutomationListEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state,
  {navigate},
) =>
  action$.pipe(
    filter(isOfType(getType(actions.updateAutomationSuccess))),
    tap(() => navigate(`/shops/${state.value.shops.selection}`)),
    ignoreElements(),
  )

const redirectToAutomationSetupEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state,
  {navigate},
) =>
  action$.pipe(
    filter(isOfType(getType(actions.createAutomationSuccess))),
    tap(action =>
      navigate(
        `/shops/${state.value.shops.selection}/automations/${
          action.payload.id
        }/setup?mergeTag=${
          action.payload.integrationConfiguration.mergeFieldTag
        }`,
      ),
    ),
    ignoreElements(),
  )

const notifyCreateAutomationSuccessEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isOfType(getType(actions.createAutomationSuccess))),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Automation created successfully.',
        timeout: 7000,
      }),
    ),
  )

const notifyUpdateAutomationSuccessEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isOfType(getType(actions.updateAutomationSuccess))),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Automation updated successfully.',
        timeout: 7000,
      }),
    ),
  )

const notifyDeleteAutomationSuccessEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isOfType(getType(actions.deleteAutomationSuccess))),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Automation deleted successfully.',
        timeout: 7000,
      }),
    ),
  )

export const epics = combineEpics(
  initializeEpic,
  loadAutomationCollectionEpic,
  loadAutomationEpic,
  createAutomationEpic,
  updateAutomationEpic,
  deleteAutomationEpic,
  redirectToAutomationSetupEpic,
  redirectToAutomationListEpic,
  notifyCreateAutomationSuccessEpic,
  notifyUpdateAutomationSuccessEpic,
  notifyDeleteAutomationSuccessEpic,
)
