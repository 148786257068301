import {Mailchimp} from "../../../api/definitions/types/integrations/mailchimp";

export const getMailchimpName = (mailchimps: Mailchimp[]) => (selectedId: string) => {
  const mailchimp = mailchimps.find(mailchimp => mailchimp.id === selectedId);
  if (!mailchimp) {
    return '';
  }

  return mailchimp.name;
}

export const getLastMailchimpId = (mailchimps: Mailchimp[]) => {
  const mailchimp = [...mailchimps].pop();
  if (!mailchimp) {
    return null;
  }

  return mailchimp.id;
}
