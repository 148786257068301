import React, {FunctionComponent} from 'react'
import {Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core'

type Props = {
  open: boolean
  handleClose: (confirm: boolean) => void
}

const CancelDialog: FunctionComponent<Props> = ({open, handleClose}) => (
  <Dialog
    open={open}
    onClose={() => handleClose(false)}
    aria-labelledby="alert-dialog-title"
  >
    <DialogTitle id="alert-dialog-title">
      Do you want to cancel your subscription?
    </DialogTitle>

    <DialogActions>
      <Button onClick={() => handleClose(false)} color="primary">
        No
      </Button>
      <Button onClick={() => handleClose(true)} color="primary" autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

export default CancelDialog
