import React, {FunctionComponent, Fragment} from 'react';
import {Link} from '@reach/router';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  link: {
    color: theme.palette.primary.main,
  },
});

type FooterProps = WithStyles<typeof styles>;

const Footer: FunctionComponent<FooterProps> = ({ classes }) => (
  <Fragment>
    <Link to="/login" className={classes.link}>← Return to login</Link>
  </Fragment>
);

export default withStyles(styles)(Footer);
