import React, {FunctionComponent} from 'react'
import LoadingDelay from 'react-loading-delay'
import {
  createStyles,
  CircularProgress,
  withStyles,
  WithStyles,
} from '@material-ui/core'

type Props = WithStyles<typeof styles>

const Loader: FunctionComponent<Props> = ({classes}) => (
  <LoadingDelay check={true} delay={750}>
    {({isDelaying}) =>
      isDelaying ? null : (
        <div className={classes.root}>
          <CircularProgress size={75} />
        </div>
      )
    }
  </LoadingDelay>
)

const styles = () =>
  createStyles({
    root: {
      overflow: 'hidden',
      position: 'absolute',
      width: '99%',
      height: '99%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

export default withStyles(styles)(Loader)
