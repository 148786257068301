import React, {FunctionComponent, Fragment, useState} from 'react'
import {RouteComponentProps} from '@reach/router'
import {
  Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core'
import {Store} from '@material-ui/icons'
import shopifyLogo from '../../../../assets/images/logo-shopify-square.png'
import wooCommerceLogo from '../../../../assets/images/logo-woo-commerce-square.png'
import magentoLogo from '../../../../assets/images/logo-magento-square.png'

const SelectShop: FunctionComponent<RouteComponentProps> = ({navigate}) => {
  const [shopifyDialog, setShopifyDialog] = useState(false);
  const [otherDialog, setOtherDialog] = useState(false);

  return (
    <Fragment>

      <List subheader={<ListSubheader>Connect your e-commerce platform to continue :</ListSubheader>}>
        <ListItem button onClick={() => setShopifyDialog(true)}>
          <ListItemIcon>
            <Avatar src={shopifyLogo} />
          </ListItemIcon>
          <ListItemText primary="Shopify" />
        </ListItem>

        <ListItem button onClick={() => navigate('woo-commerce')}>
          <ListItemIcon>
            <Avatar src={wooCommerceLogo} />
          </ListItemIcon>
          <ListItemText primary="WooCommerce" />
        </ListItem>

        <ListItem button onClick={() => navigate('magento2')}>
          <ListItemIcon>
            <Avatar src={magentoLogo} />
          </ListItemIcon>
          <ListItemText primary="Magento 2.x" />
        </ListItem>

        <ListItem button onClick={() => setOtherDialog(true)}>
          <ListItemIcon>
            <Store color="primary" fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Other" />
        </ListItem>
      </List>

      <Dialog
        open={shopifyDialog}
        onClose={() => setShopifyDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Install Personal Discount for Shopify</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Personal Discount for Shopify is a separate app. Install it in your store to start distributing unique and time-sensitive discount codes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShopifyDialog(false)} color="primary">
            Cancel
          </Button>

          <Button href="https://app.personaldiscount.me/install" color="primary">
            Install now
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={otherDialog}
        onClose={() => setOtherDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">You're using another platform?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We are expanding our application to support a maximum number of e-commerce platforms.
            Let us know the one you're using at <a href="mailto:contact@personaldiscount.io">contact@personaldiscount.io</a>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOtherDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default SelectShop
