import {combineReducers} from 'redux'
import {getType} from 'typesafe-actions'
import {actions, Action} from './actions'
import {ReactElement} from 'react'

export type State = {
  snackbar: {
    message: string
    timeout: number
    action?: ReactElement<{}>
  } | null
}

export const reducer = combineReducers<State, Action>({
  snackbar: (state = null, action) => {
    switch (action.type) {
      case getType(actions.showSnackbarMessage):
        return {...action.payload}

      case getType(actions.hideSnackbarMessage):
        return null
    }

    return state
  },
})
