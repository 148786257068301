import {connect} from 'react-redux'
import {RootState} from '../../../root-reducer'
import SubscriptionSettings from './SubscriptionSettings'
import {actions} from '../../actions'

const mapStateToProps = (state: RootState) => ({
  loading:
    state.auth.subscription.creating ||
    state.auth.subscription.updating ||
    state.auth.subscription.canceling ||
    state.auth.usage.loading,
  client: state.auth.client.item,
  usage: state.auth.usage.item,
})

const mapDispatchToProps = dispatch => ({
  loadClientUsage: (id: string) => dispatch(actions.loadClientUsage({id})),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionSettings)
