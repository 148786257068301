import React, {FunctionComponent, Fragment, useContext} from 'react'
import {Grid} from '@material-ui/core'

import MailchimpField from '../../../../../integrations/components/MailchimpField'
import MailchimpListField from '../../../../../integrations/components/MailchimpListField'
import MailchimpMergeTagField from "../../../../../integrations/components/MailchimpMergeTagField";
import {FormContext} from "../../../../../shared/components/forms/Form";

const MailchimpSubscriberBirthdayFields: FunctionComponent<{}> = () => {
  const form = useContext(FormContext)

  return (
    <Fragment>
      <Grid item xs={12}>
        <MailchimpField name="condition.integrationId" />
      </Grid>

      {form.values['condition.integrationId'] && (
        <Grid item xs={12} md={6}>
          <MailchimpListField
            name="condition.listId"
            mailchimpId={form.values['condition.integrationId']}
          />
        </Grid>
      )}

      {form.values['condition.listId'] && (
        <Grid item xs={12} md={6}>
          <MailchimpMergeTagField
            name="condition.birthdayMergeField"
            mailchimpId={form.values['condition.integrationId']}
            listId={form.values['condition.listId']}
          />
        </Grid>
      )}
    </Fragment>
  )
}

export default MailchimpSubscriberBirthdayFields
