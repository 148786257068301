import React from 'react'
import {connect} from 'react-redux'
import {RootState} from '../../../../../root-reducer'
import {getSelectedShop} from '../../../../../shops/selectors'
import MagentoFields from './MagentoFields'

const mapStateToProps = (state: RootState) => ({
  selectedShop: getSelectedShop(state),
})

export default connect(mapStateToProps)(MagentoFields)
