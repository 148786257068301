import React from 'react'
import {connect} from 'react-redux'
import {RootState} from '../../../root-reducer'
import {actions} from '../../actions'
import Snackbar from './Snackbar'

const mapStateToProps = (state: RootState) => ({
  snackbar: state.shared.snackbar,
})

const mapDispatchToProps = {
  onClose: actions.hideSnackbarMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Snackbar)
