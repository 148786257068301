import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core'

import {store} from './features/store'
import Snackbar from './features/shared/components/Snackbar'
import App from './apps/App'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#34495E',
    },
    secondary: {
      main: '#EAC420',
    },
  },
})

const isPopup = new URL(window.location.href).searchParams.get('popup')

if (!isPopup) {
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Snackbar />

        <App />
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('root'),
  )
}
