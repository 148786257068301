import {createSelector} from 'reselect'
import {RootState} from '../root-reducer'
import {getSelectedShop} from '../shops/selectors'

const automations = (state: RootState) => state.automations.collection.items

export const getAutomations = createSelector(
  automations,
  getSelectedShop,
  (automations, selectedShop) =>
    selectedShop
      ? automations.filter(automation => automation.shopId === selectedShop.id)
      : automations,
)
