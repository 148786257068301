import {ReactElement} from 'react'
import {ActionType, createStandardAction} from 'typesafe-actions'

const INITIALIZE_APP = 'shared/INITIALIZE_APP'

const SHOW_SNACKBAR_MESSAGE = 'shared/SHOW_SNACKBAR_MESSAGE'
const HIDE_SNACKBAR_MESSAGE = 'shared/HIDE_SNACKBAR_MESSAGE'

export const actions = {
  initializeApp: createStandardAction(INITIALIZE_APP)(),
  showSnackbarMessage: createStandardAction(SHOW_SNACKBAR_MESSAGE)<{
    message: string
    timeout: number
    action?: ReactElement<{}>
  }>(),
  hideSnackbarMessage: createStandardAction(HIDE_SNACKBAR_MESSAGE)(),
}

export type Action = ActionType<typeof actions>
