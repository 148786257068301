import {ActionType, createStandardAction} from 'typesafe-actions'
import {Problem} from '../../api/definitions/responses/problem'
import {GetShopCollection} from '../../api/definitions/responses/shops/get-shop-collection'
import {GetMagentoSalesRuleCollection} from '../../api/definitions/responses/shops/get-magento-sales-rule-collection'
import {ConnectMagento as ConnectMagentoRequest} from '../../api/definitions/requests/shops/connect-magento'
import {ConnectMagento as ConnectMagentoResponse} from '../../api/definitions/responses/shops/connect-magento'

const LOAD_SHOPS = 'shops/LOAD_SHOPS'
const LOAD_SHOPS_SUCCESS = 'shops/LOAD_SHOPS_SUCCESS'
const LOAD_SHOPS_FAILURE = 'shops/LOAD_SHOPS_FAILURE'

const SELECT_SHOP = 'shops/SELECT_SHOP'

const LOAD_MAGENTO_SALES_RULES = 'shops/LOAD_MAGENTO_SALES_RULES'
const LOAD_MAGENTO_SALES_RULES_SUCCESS =
  'shops/LOAD_MAGENTO_SALES_RULES_SUCCESS'
const LOAD_MAGENTO_SALES_RULES_FAILURE =
  'shops/LOAD_MAGENTO_SALES_RULES_FAILURE'

const CONNECT_MAGENTO = 'shops/CONNECT_MAGENTO'
const CONNECT_MAGENTO_SUCCESS = 'shops/CONNECT_MAGENTO_SUCCESS'
const CONNECT_MAGENTO_FAILURE = 'shops/CONNECT_MAGENTO_FAILURE'

export const actions = {
  loadShops: createStandardAction(LOAD_SHOPS)(),
  loadShopsSuccess: createStandardAction(LOAD_SHOPS_SUCCESS)<
    GetShopCollection
  >(),
  loadShopsFailure: createStandardAction(LOAD_SHOPS_FAILURE)<Problem>(),

  selectShop: createStandardAction(SELECT_SHOP)<string>(),

  loadMagentoSalesRules: createStandardAction(LOAD_MAGENTO_SALES_RULES)<{
    shopId: string
  }>(),
  loadMagentoSalesRulesSuccess: createStandardAction(
    LOAD_MAGENTO_SALES_RULES_SUCCESS,
  )<GetMagentoSalesRuleCollection>(),
  loadMagentoSalesRulesFailure: createStandardAction(
    LOAD_MAGENTO_SALES_RULES_FAILURE,
  )<Problem>(),

  connectMagento: createStandardAction(CONNECT_MAGENTO)<
    ConnectMagentoRequest
  >(),
  connectMagentoSuccess: createStandardAction(CONNECT_MAGENTO_SUCCESS)<
    ConnectMagentoResponse
  >(),
  connectMagentoFailure: createStandardAction(CONNECT_MAGENTO_FAILURE)<
    Problem
  >(),
}

export type Action = ActionType<typeof actions>
