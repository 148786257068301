import React, {FunctionComponent, Fragment, useState} from 'react'
import {Grid} from '@material-ui/core'

import {Field} from '../../../../../shared/components/forms/Field'
import MailchimpField from '../../../../../integrations/components/MailchimpField'
import MailchimpListField from '../../../../../integrations/components/MailchimpListField'

const MailchimpNewSubscriberFields: FunctionComponent<{}> = () => (
  <Fragment>
    <Grid item xs={12} md={6}>
      <MailchimpField name="condition.integrationId" />
    </Grid>

    <Field name="condition.integrationId">
      {({input}) =>
        input.value === '' ? null : (
          <Grid item xs={12} md={6}>
            <MailchimpListField
              name="condition.listId"
              mailchimpId={input.value}
            />
          </Grid>
        )
      }
    </Field>
  </Fragment>
)

export default MailchimpNewSubscriberFields
