import {connect} from "react-redux";
import {compose, pipe, tap, prop} from "ramda";
import {actions} from '../../actions';
import {RootState} from '../../../root-reducer';
import RequestPasswordForm from "./RequestPasswordForm";

const mapSubmitToAction = (clientId, code) => submitEvent => actions.submitResetPasswordForm({
  clientId,
  code,
  data: {newPassword: submitEvent.password.value},
});

const mapStateToProps = (state : RootState) => ({
  isSubmitting: state.auth.resetPasswordForm.submitting,
  problem: state.auth.resetPasswordForm.problem,
});

const mapDispatchToProps = (dispatch, { id, code }) => ({
  handleSubmit: pipe(
    tap<any>(event => event.preventDefault()),
    compose(dispatch, mapSubmitToAction(id, code), prop('target'))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestPasswordForm);
