import {connect} from "react-redux";
import {compose, pipe, tap, prop} from "ramda";
import {actions} from '../../actions';
import {RootState} from '../../../root-reducer';
import RegisterForm from "./RegisterForm";

const mapSubmitToAction = submitEvent => actions.submitRegisterForm({
  firstName: submitEvent.firstName.value,
  email: submitEvent.email.value,
  password: submitEvent.password.value,
});

const mapStateToProps = (state : RootState) => ({
  isSubmitting: state.auth.registerForm.submitting || state.auth.client.loading,
  problem: state.auth.registerForm.problem,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: pipe(
    tap<any>(event => event.preventDefault()),
    compose(dispatch, mapSubmitToAction, prop('target'))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterForm);
