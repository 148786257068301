import {createStore, applyMiddleware, compose} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import { navigate } from "@reach/router"
import Cookies from 'universal-cookie/es6';
import jwtDecode from "jwt-decode";
import {RootAction} from './root-action';
import rootReducer, {RootState} from './root-reducer';
import rootEpic from './root-epic';
import {actions} from "./shared/actions";

// @ts-ignore
const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const cookies = new Cookies();
const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>({
  dependencies: {
    cookies,
    navigate,
    jwtDecode,
  },
});
const middlewares = applyMiddleware(epicMiddleware);
const defaultState = {
  auth: {
    token: cookies.get('token') || null
  }
};
const store = createStore(rootReducer, defaultState, composeEnhancers(middlewares));

epicMiddleware.run(rootEpic);
store.dispatch(actions.initializeApp());

export { store };
