import React, {FunctionComponent} from 'react'
import {RouteComponentProps} from '@reach/router'
import {
  createStyles,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import ConnectShopForm from '../../features/shops/components/ConnectShopForm'

type Props = RouteComponentProps & WithStyles<typeof styles>

const ConnectShop: FunctionComponent<Props> = ({classes}) => (
  <div className={classes.layout}>
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography component="h2" variant="h5" className={classes.headerTitle}>
          Welcome to Personal Discount!
        </Typography>
      </div>

      <div className={classes.content}>
        <ConnectShopForm />
      </div>
    </Paper>
  </div>
)

const styles = theme =>
  createStyles({
    layout: {
      width: 650,
      marginTop: theme.spacing.unit * 2,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down(650 + theme.spacing.unit * 2 * 2)]: {
        width: 'auto',
      },
    },
    paper: {},
    header: {
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      boxAlign: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      lineHeight: '2.25rem',
      minHeight: '180px',
    },
    headerTitle: {
      textAlign: 'center',
      color: '#fff',
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    content: {
      padding: '40px 72px 64px 72px',
      [theme.breakpoints.down(650 + theme.spacing.unit * 2 * 2)]: {
        padding: '10px 10px 10px 10px',
      },
    },
    card: {
      maxWidth: 200,
    },
  })

export default withStyles(styles)(ConnectShop)
