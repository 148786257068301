import React, {FunctionComponent} from 'react';
import {RouteComponentProps} from "@reach/router";
import {connect} from "react-redux";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import SubscriptionSettings from "../../../features/auth/components/SubscriptionSettings";

type Props = WithStyles<typeof styles> & RouteComponentProps;

const Settings: FunctionComponent<Props> = ({ classes }) => {
  return (
    <div className={classes.layout}>
      <SubscriptionSettings />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(1030 + 300)]: {
      width: 1030,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

export default connect(null)(withStyles(styles)(Settings));
