import {createSelector} from 'reselect'
import {RootState} from '../root-reducer'
import {getLastMailchimpId} from './utils/mailchimp'

const integrations = (state: RootState) => state.integrations.items
const addedMailchimpId = (state: RootState) => state.integrations.addedMailchimp

export const getMailchimpIntegrations = createSelector(
  integrations,
  integrations =>
    integrations.filter(integration => integration.type === 'mailchimp'),
)

export const getDefaultMailchimpId = createSelector(
  addedMailchimpId,
  getMailchimpIntegrations,
  (addedMailchimpIntegration, mailchimps) =>
    addedMailchimpIntegration || getLastMailchimpId(mailchimps),
)
