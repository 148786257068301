import {combineReducers} from 'redux'
import {getType} from 'typesafe-actions'
import {actions} from './actions'
import {RootAction} from '../root-action'
import {Problem} from '../../api/definitions/responses/problem'
import {Automation} from '../../api/definitions/types/automation/automation'
import {PartialAutomation} from '../../api/definitions/types/automation/partial-automation'

export type State = {
  collection: {
    loading: boolean
    deleting: boolean
    items: PartialAutomation[]
  }
  automationForm: {
    loading: boolean
    problem: Problem | null
    automation: Automation | null
  }
}

export const reducer = combineReducers<State, RootAction>({
  collection: (
    state = {loading: false, deleting: false, items: []},
    action,
  ) => {
    switch (action.type) {
      case getType(actions.loadAutomationCollection):
        return {...state, loading: true}

      case getType(actions.loadAutomationCollectionSuccess):
        return {...state, loading: false, items: action.payload}

      case getType(actions.loadAutomationCollectionFailure):
        return {...state, loading: false}

      case getType(actions.createAutomationSuccess):
        return {
          ...state,
          items: [
            ...state.items,
            {
              ...action.payload,
              conditionType: action.payload.condition.type,
              numberOfCoupons: 0,
            },
          ],
        }

      case getType(actions.updateAutomationSuccess):
        return {
          ...state,
          items: state.items.map(automation =>
            automation.id === action.payload.id
              ? {...automation, ...action.payload.response}
              : automation,
          ),
        }

      case getType(actions.deleteAutomation):
        return {...state, deleting: true}

      case getType(actions.deleteAutomationFailure):
        return {...state, deleting: false}

      case getType(actions.deleteAutomationSuccess):
        return {
          ...state,
          deleting: false,
          items: state.items.filter(item => item.id !== action.payload.id),
        }
    }

    return state
  },
  automationForm: (
    state = {loading: false, problem: null, automation: null},
    action,
  ) => {
    switch (action.type) {
      case getType(actions.loadAutomation):
        return {...state, automation: null}

      case getType(actions.loadAutomationSuccess):
        return {...state, automation: action.payload.response}

      case getType(actions.createAutomation):
      case getType(actions.updateAutomation):
        return {...state, loading: true}

      case getType(actions.createAutomationSuccess):
      case getType(actions.updateAutomationSuccess):
        return {...state, loading: false, problem: null, automation: null}

      case getType(actions.createAutomationFailure):
        return {...state, loading: false, problem: action.payload}

      case getType(actions.updateAutomationFailure):
        return {...state, loading: false, problem: action.payload.response}
    }

    return state
  },
})
