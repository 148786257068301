import {combineReducers} from 'redux'
import {getType} from 'typesafe-actions'
import {actions, Action} from './actions'
import {Problem} from '../../api/definitions/responses/problem'
import {Integration} from '../../api/definitions/types/integration'
import {Id} from '../../api/definitions/types/id'
import {MailchimpList} from '../../api/definitions/types/integrations/mailchimp-list'
import {MailchimpMergeField} from '../../api/definitions/types/integrations/mailchimp-merge-field'

export type State = {
  loading: boolean
  items: Integration[]
  problem: Problem | null
  addingMailchimp: boolean
  addedMailchimp: Id | null
  mailchimpList: {
    loading: boolean
    items: {[key: string]: MailchimpList[]}
  }
  mailchimpMergeFields: {
    loading: boolean
    items: {[key: string]: MailchimpMergeField[]}
  }
}

export const reducer = combineReducers<State, Action>({
  loading: (state = false, action) => {
    switch (action.type) {
      case getType(actions.loadIntegrations):
        return true

      case getType(actions.loadIntegrationsSuccess):
      case getType(actions.loadIntegrationsFailure):
        return false
    }

    return state
  },
  items: (state = [], action) => {
    switch (action.type) {
      case getType(actions.loadIntegrationsSuccess):
        return action.payload

      case getType(actions.createMailchimpIntegrationSuccess):
        return [...state, action.payload]
    }

    return state
  },
  problem: (state = null, action) => {
    switch (action.type) {
      case getType(actions.loadIntegrationsFailure):
        return action.payload

      case getType(actions.loadIntegrationsSuccess):
        return null
    }

    return state
  },
  addingMailchimp: (state = false, action) => {
    switch (action.type) {
      case getType(actions.createMailchimpIntegration):
        return true

      case getType(actions.createMailchimpIntegrationSuccess):
      case getType(actions.createMailchimpIntegrationFailure):
        return false
    }

    return state
  },
  addedMailchimp: (state = null, action) => {
    switch (action.type) {
      case getType(actions.createMailchimpIntegration):
        return null

      case getType(actions.createMailchimpIntegrationSuccess):
        return action.payload.id
    }

    return state
  },
  mailchimpList: (state = {loading: false, items: {}}, action) => {
    switch (action.type) {
      case getType(actions.loadMailchimpLists):
        return {...state, loading: true}

      case getType(actions.loadMailchimpListsSuccess):
        return {
          loading: false,
          items: {
            ...state.items,
            [action.payload.mailchimpId]: action.payload.lists,
          },
        }

      case getType(actions.loadMailchimpListsFailure):
        return {...state, loading: false}
    }

    return state
  },
  mailchimpMergeFields: (state = {loading: false, items: {}}, action) => {
    switch (action.type) {
      case getType(actions.loadMailchimpMergeFields):
        return {...state, loading: true}

      case getType(actions.loadMailchimpMergeFieldsSuccess):
        return {
          loading: false,
          items: {
            ...state.items,
            [action.payload.listId]: action.payload.mergeFields,
          },
        }

      case getType(actions.loadMailchimpListsFailure):
        return {...state, loading: false}
    }

    return state
  },
})
