import {Client} from '../api/definitions/types/client'

export const loadPaddleSDK = () => {
  if (document.getElementById('paddle-sdk')) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('id', 'paddle-sdk')
  script.src = 'https://cdn.paddle.com/paddle/paddle.js'
  script.async = true
  script.onload = () => {
    ;(window as any).Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || ''),
    })
  }

  document.body.appendChild(script)
}

type OnSuccessFn = () => void

export const createPaddleSubscription = (
  client: Client,
  paddleProduct: string,
  onSuccess: OnSuccessFn,
) => {
  ;(window as any).Paddle.Checkout.open({
    product: paddleProduct,
    email: client.email,
    passthrough: client.id,
    disableLogout: true,
    allowQuantity: false,
    successCallback: () => onSuccess(),
  })
}

export const updatePaddleSubscriptionPayment = (client: Client) => {
  if (!client.subscription) {
    return
  }

  ;(window as any).Paddle.Checkout.open({
    override: client.subscription.updateUrl,
    email: client.email,
    passthrough: client.id,
    disableLogout: true,
    allowQuantity: false,
  })
}
