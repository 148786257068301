import React, {FunctionComponent} from 'react'
import {Router, Redirect, RouteComponentProps} from '@reach/router'
import Layout from './Layout'
import ConnectShop from './ConnectShop'

const App: FunctionComponent<{}> = () => (
  <Router>
    <Layout path="/">
      <ConnectShop path="setup/*" />

      <Redirect from="*" to="setup" noThrow />
    </Layout>
  </Router>
)

export default App
