import React, {FunctionComponent, useEffect} from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import {MailchimpList} from '../../../../api/definitions/types/integrations/mailchimp-list'
import {Id} from '../../../../api/definitions/types/id'
import {Field} from '../../../shared/components/forms/Field'
import {actions} from '../../actions'

export type Props = {
  name: string
  mailchimpId: Id
  loading: boolean
  lists: MailchimpList[]
  loadLists: typeof actions.loadMailchimpLists
}

const MailchimpListField: FunctionComponent<Props> = ({
  mailchimpId,
  loading,
  lists,
  loadLists,
  name,
}) => {
  useEffect(() => {
    loadLists({mailchimpId})
  }, [mailchimpId])

  return (
    <Field name={name}>
      {({input, meta}) => (
        <FormControl
          error={meta.errors.length !== 0}
          disabled={'id' in meta.values}
          fullWidth
        >
          <InputLabel htmlFor={input.name}>List</InputLabel>

          <Select
            value={input.value}
            onChange={input.onChange}
            inputProps={{
              name: input.name,
              id: input.name,
            }}
          >
            {lists.map(list => (
              <MenuItem selected={true} key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}

            {lists.length === 0 && loading && (
              <MenuItem disabled>Loading lists...</MenuItem>
            )}
            {lists.length === 0 && !loading && (
              <MenuItem disabled>No list found</MenuItem>
            )}
          </Select>

          {meta.errors.map(error => (
            <FormHelperText key={error.message}>{error.message}</FormHelperText>
          ))}
        </FormControl>
      )}
    </Field>
  )
}

export default MailchimpListField
