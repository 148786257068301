import React, {FunctionComponent} from "react";
import * as queryString from 'query-string';
import {RouteComponentProps} from "@reach/router";
import AnonymousLayout from '../Layout';
import ResetPasswordForm from '../../../features/auth/components/ResetPasswordForm';
import Footer from "./Footer";

type Props = RouteComponentProps<{
  id: string;
  code: string;
}>;

const Login: FunctionComponent<Props> = ({ location }) => {
  const queryParams = queryString.parse(location.search);

  console.log(queryParams);

  return (
    <AnonymousLayout title="Reset my password" footer={<Footer />}>
      <ResetPasswordForm code={String(queryParams.code)} id={String(queryParams.id)} />
    </AnonymousLayout>
  );
}

export default Login;
