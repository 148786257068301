import React, {FunctionComponent} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {Star as StarIcon} from '@material-ui/icons'

const tiers = [
  {
    title: 'Growing',
    price: '9',
    description: ['All features', '3 shops', '500 coupons per month'],
    buttonText: 'Select plan',
    buttonVariant: 'contained' as 'contained',
    paddleProduct: '550175',
  },
  {
    title: 'Pro',
    price: '49',
    description: ['All features', '5 shops', '5,000 coupons per month'],
    buttonText: 'Select plan',
    buttonVariant: 'outlined' as 'outlined',
    paddleProduct: '550174',
  },
]

const styles = theme =>
  createStyles({
    cardHeader: {
      backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing.unit * 2,
    },
    cardActions: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing.unit * 2,
      },
    },
    pricingWarning: {
      paddingTop: theme.spacing.unit * 2,
    }
  })

type Props = WithStyles<typeof styles> & {
  open: boolean
  handleClose: () => void
  handleSelection: (product: string) => void
  disabledProduct?: string
}

const PlansDialog: FunctionComponent<Props> = ({
  classes,
  open,
  handleClose,
  handleSelection,
  disabledProduct,
}) => (
  <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
    <DialogContent>
      <Grid container spacing={40} alignItems="flex-end">
        {tiers.map(tier => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={6}
            md={6}
          >
            <Card>
              <CardHeader
                title={tier.title}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{align: 'center'}}
                action={tier.title === 'Growing' ? <StarIcon /> : null}
                className={classes.cardHeader}
              />

              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    ${tier.price}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /mo
                  </Typography>
                </div>
                {tier.description.map(line => (
                  <Typography variant="subtitle1" align="center" key={line}>
                    {line}
                  </Typography>
                ))}
              </CardContent>

              <CardActions className={classes.cardActions}>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color="primary"
                  disabled={disabledProduct === tier.paddleProduct}
                  onClick={() => {
                    handleClose()
                    handleSelection(tier.paddleProduct)
                  }}
                >
                  {disabledProduct !== tier.paddleProduct
                    ? tier.buttonText
                    : 'Current plan'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Typography className={classes.pricingWarning}>
        Indicated prices are before taxes. Taxes may apply based on your status and location.
      </Typography>
    </DialogContent>

    <Divider />
    <DialogActions>
      <Button onClick={() => handleClose()} color="primary" autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
)

export default withStyles(styles)(PlansDialog)
