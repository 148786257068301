import React, {FunctionComponent} from 'react'
import {Router, Redirect} from '@reach/router'
import SelectShop from './SelectShop'
import ConnectWooCommerceForm from './ConnectWooCommerceForm'
import ConnectMagentoForm from './ConnectMagentoForm'

const ConnectShopForm: FunctionComponent = () => (
  <Router>
    <SelectShop path="/" />
    <ConnectWooCommerceForm path="/woo-commerce" />
    <ConnectMagentoForm path="/magento2" />

    <Redirect from="*" to="/" noThrow />
  </Router>
)

export default ConnectShopForm
