import * as React from 'react'
import {
  FunctionComponent,
  ReactNode,
  useContext,
  Fragment,
  FormEvent,
  useEffect,
} from 'react'
import {FieldValue, FormContext} from './Form'
import {Problem} from '../../../../api/definitions/responses/problem'

type OnChangeEvent = FormEvent<
  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
>

const getValue = (event: OnChangeEvent) => {
  const {
    target: {type, value, checked},
  } = event as any

  switch (type) {
    case 'checkbox':
      return !!checked
    case 'number':
      return Number(value)
  }

  return value
}

type ChildrenProps = {
  input: {
    name: string
    value: FieldValue
    checked: boolean | undefined
    onChange: (event: OnChangeEvent) => void
  }
  meta: {
    setValue: (value: FieldValue) => void
    touched: boolean
    errors: Problem['errors']
    values: any
  }
}

type Props = {
  name: string
  defaultValue?: FieldValue
  formatter?: {
    toValue: (display: any) => any
    toDisplay: (value: any) => any
  }
  children: (props: ChildrenProps) => ReactNode
}

export const Field: FunctionComponent<Props> = ({
  name,
  defaultValue,
  formatter,
  children,
}) => {
  const form = useContext(FormContext)

  const onChange = (event: OnChangeEvent) =>
    form.setValue(
      name,
      formatter ? formatter.toValue(getValue(event)) : getValue(event),
    )

  const input = {
    name,
    value: formatter
      ? formatter.toDisplay(form.values[name])
      : form.values[name] || '',
    checked: !!form.values[name],
    onChange,
  }

  const meta = {
    setValue: value => form.setValue(name, value),
    touched: form.touched.has(name),
    errors: form.problem
      ? form.problem.errors.filter(error => error.property === name)
      : [],
    values: form.values,
  }

  useEffect(() => {
    if (!form.values[name] && !meta.touched && defaultValue) {
      form.setValue(name, defaultValue)
    }
  })

  return <Fragment>{children({input, meta})}</Fragment>
}
