import React, {FunctionComponent} from 'react'
import {connect} from 'react-redux'
import {Router, Redirect} from '@reach/router'
import {Shop} from '../../api/definitions/types/shop'
import {RootState} from '../../features/root-reducer'
import {getDefaultShop} from '../../features/shops/selectors'
import Layout from './Layout'
import ShopSelector from './ShopSelector'
import Dashboard from './pages/Dashboard'
import Settings from './pages/Settings'
import ConnectShop from './pages/ConnectShop'
import CreateAutomation from './pages/CreateAutomation'
import UpdateAutomation from './pages/UpdateAutomation'
import SetupAutomation from './pages/SetupAutomation'

type Props = {
  defaultShop: Shop | null
}

const App: FunctionComponent<Props> = ({defaultShop}) => {
  if (!defaultShop) {
    return null
  }

  return (
    <Layout>
      <Router>
        <ShopSelector path="shops/:shopId">
          <Dashboard path="/" />

          <CreateAutomation path="create-automation" />
          <UpdateAutomation path="automations/:automationId" />
          <SetupAutomation path="automations/:automationId/setup" />

          <Redirect from="*" to={`shops/${defaultShop.id}`} noThrow />
        </ShopSelector>

        <Settings path="settings" />
        <ConnectShop path="connect-shop/*" />

        <Redirect from="*" to={`shops/${defaultShop.id}`} noThrow />
      </Router>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  defaultShop: getDefaultShop(state),
})

export default connect(mapStateToProps)(App)
