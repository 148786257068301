import React from 'react'
import {connect} from 'react-redux'
import {navigate} from '@reach/router'
import {actions} from '../../../actions'
import {actions as sharedActions} from '../../../../shared/actions'
import {RootState} from '../../../../root-reducer'
import ConnectWooCommerceForm from './ConnectWooCommerceForm'
import {openPopup} from '../../../../../utils/popup'
import {wooCommerceConnectUrl} from '../../../../../api/urls'
import {Id} from '../../../../../api/definitions/types/id'
import {getSelectedShop} from '../../../selectors'

const mapStateToProps = (state: RootState) => ({
  client: state.auth.client.item,
  selectedShop: getSelectedShop(state),
})

const mapDispatchToProps = dispatch => ({
  createShop: (client: Id, selectedShop: Id, shopUrl: string) => {
    const url = shopUrl.replace(/\/+$/, '')
    const urlParams = {
      app_name: 'Personal Discount',
      scope: 'write',
      user_id: url,
      return_url: `${window.location.href.split('?')[0]}?popup=1`,
      callback_url: wooCommerceConnectUrl(client),
    }

    const onError = () =>
      dispatch(
        sharedActions.showSnackbarMessage({
          message: 'Unable to connect the shop.',
          timeout: 7000,
        }),
      )
    const onSuccess = () => {
      dispatch(actions.loadShops())
      dispatch(
        sharedActions.showSnackbarMessage({
          message: 'Shop has been connected with success.',
          timeout: 7000,
        }),
      )

      navigate(`/`)
    }

    openPopup('woo-commerce', `${url}/wc-auth/v1/authorize`, urlParams)
      .then(params => (params.get('success') === '1' ? onSuccess() : onError()))
      .catch(() => onError())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectWooCommerceForm)
