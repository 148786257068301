import {connect} from "react-redux";
import {actions} from "../../../actions";
import {Id} from "../../../../../api/definitions/types/id";
import ActionButtons from "./ActionButtons";

const mapDispatchToProps = (dispatch) => ({
  createSubscription: (id: Id) => dispatch(actions.createSubscription({ id })),
  updateSubscription: (id: Id, newPlan: string) => dispatch(actions.updateSubscription({ id, data: {
    newPlanId: newPlan,
  }})),
  cancelSubscription: (id: Id) => dispatch(actions.cancelSubscription({ id })),
})

export default connect(null, mapDispatchToProps)(ActionButtons);
