import React, {FunctionComponent} from 'react'
import {RouteComponentProps, Link} from '@reach/router'
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {Client} from '../../../../../api/definitions/types/client'
import {Form} from '../../../../shared/components/forms/Form'
import {Field} from '../../../../shared/components/forms/Field'
import {Shop} from '../../../../../api/definitions/types/shop'
import wooCommerceLogo from '../../../../../assets/images/logo-woo-commerce.png'

type Props = RouteComponentProps &
  WithStyles<typeof styles> & {
    client: Client | null
    selectedShop: Shop | null
    createShop: any
  }

const ConnectWooCommerceForm: FunctionComponent<Props> = ({
  client,
  selectedShop,
  createShop,
  classes,
}) => (
  <Form
    problem={null}
    submitting={false}
    onSubmit={values =>
      createShop(
        client ? client.id : '',
        selectedShop ? selectedShop.id : '',
        values['url'],
      )
    }
  >
    {({handleSubmit, submitting}) => (
      <form className={classes.form} onSubmit={handleSubmit}>
        <img src={wooCommerceLogo} alt="WooCommerce logo" />

        <Field name="url" defaultValue="https://">
          {({input, meta}) => (
            <FormControl
              margin="normal"
              error={meta.errors.length > 0}
              required
              fullWidth
            >
              <InputLabel htmlFor={input.name}>Shop URL</InputLabel>
              <Input
                {...input}
                placeholder="https://my-shop.com/"
                autoFocus={true}
              />

              {meta.errors.length === 0 && (
                <FormHelperText>
                  This should be the root domain of your shop, eg: https://my-shop.com/
                </FormHelperText>
              )}

              {meta.errors.map(error => (
                <FormHelperText key={error.message}>
                  {error.message}
                </FormHelperText>
              ))}
            </FormControl>
          )}
        </Field>

        <div className={classes.buttonGroup}>
          <Button
            component={props => <Link to="../" {...props} />}
            type="submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={submitting}
          >
            Connect Shop
          </Button>
        </div>
      </form>
    )}
  </Form>
)

const styles = theme =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      marginTop: theme.spacing.unit * 3,
    },
    button: {
      margin: theme.spacing.unit,
    },
  })

export default withStyles(styles)(ConnectWooCommerceForm)
