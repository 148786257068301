import React, {FunctionComponent} from 'react';
import {RouteComponentProps} from "@reach/router";
import {createStyles, Paper, Theme, withStyles, WithStyles} from "@material-ui/core";
import ConnectShopForm from "../../../features/shops/components/ConnectShopForm";

const styles = (theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up(650 + 300 + theme.spacing.unit * 2 * 2)]: {
      width: 650,
    },
  },
  paper: {
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxAlign: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '2.25rem',
    minHeight: '180px',
  },
  headerTitle: {
    color: '#fff',
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  content: {
    padding: '40px 72px 64px 72px',
  }
});

type Props = RouteComponentProps & WithStyles<typeof styles>;

const ConnectShop: FunctionComponent<Props> = ({ classes }) => (
  <div className={classes.layout}>
    <Paper className={classes.paper}>
      <div className={classes.content}>
        <ConnectShopForm />
      </div>
    </Paper>
  </div>
);

export default withStyles(styles)(ConnectShop);
