import React, {FunctionComponent} from 'react'
import {FormHelperText} from '@material-ui/core'
import {Problem} from '../../../../api/definitions/responses/problem'

type Props = {
  error: Problem['errors'][0]
}

const FieldProblem: FunctionComponent<Props> = ({error}) => (
  <FormHelperText>{error.message}</FormHelperText>
)

export const hasProblem = (problem: Problem | null, field: string): boolean => {
  if (!problem || !problem.errors) {
    return false
  }

  return problem.errors.some(error => error.property === field)
}

export const renderProblem = (problem: Problem | null, field: string) => {
  if (!problem || !problem.errors) {
    return null
  }

  return problem.errors
    .filter(error => error.property === field)
    .map((error, index) => <FieldProblem key={index} error={error} />)
}
