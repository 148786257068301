import React from 'react'
import {connect} from 'react-redux'

import {RootState} from '../../../root-reducer'
import {actions} from '../../actions'
import MagentoSalesRulesField from './MagentoSalesRulesField'
import {Id} from '../../../../api/definitions/types/id'

const mapStateToProps = (state: RootState) => ({
  loading: state.shops.magentoSalesRules.loading,
  rules: state.shops.magentoSalesRules.items,
})

const mapDispatchToProps = dispatch => ({
  loadRules: (shopId: Id) => dispatch(actions.loadMagentoSalesRules({shopId})),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MagentoSalesRulesField)
