import React, {FunctionComponent} from 'react'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@material-ui/core'
import {ConditionField} from '../../../../../shared/components/forms/ConditionField'
import {Field} from '../../../../../shared/components/forms/Field'

const WooCommerceFields: FunctionComponent<{}> = () => (
  <Grid container spacing={24}>
    <Grid item md={12} xs={12}>
      <Field name="couponConfiguration.codePattern" defaultValue="HELLO">
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <InputLabel htmlFor={input.name}>Code prefix</InputLabel>
            <Input {...input} />

            {input.value && (
              <FormHelperText>
                Sample code: {input.value}-A2FJSKDI9
              </FormHelperText>
            )}
            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.discountType" defaultValue="percent">
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <InputLabel htmlFor={input.name}>Discount type</InputLabel>

            <Select {...input}>
              <MenuItem value="percent">Percentage discount</MenuItem>
              <MenuItem value="fixed_cart">Fixed cart discount</MenuItem>
            </Select>

            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.amount" defaultValue="10">
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <ConditionField
              when="couponConfiguration.discountType"
              is="percent"
            >
              <InputLabel htmlFor={input.name}>Discount percentage</InputLabel>
              <Input
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                {...input}
              />
            </ConditionField>

            <ConditionField
              when="couponConfiguration.discountType"
              is="fixed_cart"
            >
              <InputLabel htmlFor="couponConfiguration.amount">
                Discount amount
              </InputLabel>
              <Input {...input} />
            </ConditionField>

            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field
        name="couponConfiguration.expirationTime"
        defaultValue={2592000} // 30 days
        formatter={{
          toValue: value => value * 86400,
          toDisplay: value => (value ? Math.floor(value / 86400) : 0),
        }}
      >
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <InputLabel htmlFor={input.name}>Validity time</InputLabel>
            <Input
              type="number"
              endAdornment={
                <InputAdornment position="end">days</InputAdornment>
              }
              {...input}
            />

            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.usageLimit" defaultValue={1}>
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} fullWidth>
            <InputLabel htmlFor={input.name}>Usage limit</InputLabel>
            <Input type="number" {...input} />

            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.freeShipping" defaultValue={false}>
        {({input}) => (
          <FormControlLabel
            control={
              <Switch checked={input.checked} onChange={input.onChange} />
            }
            label="Free shipping"
          />
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.individualUse" defaultValue={true}>
        {({input}) => (
          <FormControlLabel
            control={
              <Switch checked={input.checked} onChange={input.onChange} />
            }
            label="Individual use only"
          />
        )}
      </Field>
    </Grid>

    <Grid item md={6} xs={12}>
      <Field name="couponConfiguration.excludeSaleItems" defaultValue={false}>
        {({input}) => (
          <FormControlLabel
            control={
              <Switch checked={input.checked} onChange={input.onChange} />
            }
            label="Exclude sale items"
          />
        )}
      </Field>
    </Grid>
  </Grid>
)

export default WooCommerceFields
