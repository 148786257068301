export const openPopup = (
  id: string,
  url: string,
  params: object,
): Promise<URLSearchParams> => {
  const urlParams = new URLSearchParams({...params} as any).toString()
  const fullUrl = `${url}?${urlParams}`
  const popup = window.open(fullUrl, id, 'height=1000&width=600')

  return new Promise((resolve, reject) => {
    let interval = window.setInterval(() => {
      const close = () => {
        if (popup) {
          popup.close()
        }

        window.clearInterval(interval)
      }

      try {
        if (!popup || popup.closed !== false) {
          close()
          reject(new Error('The popup was closed.'))

          return
        }

        if (
          popup.location.href === fullUrl ||
          popup.location.pathname === 'blank'
        ) {
          return
        }

        resolve(new URL(popup.location.href).searchParams)
        close()
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
      }
    }, 500)
  })
}
