import React, {FunctionComponent, Fragment} from 'react';
import {RouteComponentProps, navigate} from "@reach/router";
import {connect} from "react-redux";
import {
  Button,
  createStyles,
  Grid,
  Theme, Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import {FlashOn as FlashOnIcon} from '@material-ui/icons';
import {RootState} from "../../../features/root-reducer";
import {Automation} from "../../../api/definitions/types/automation/automation";
import {Shop} from "../../../api/definitions/types/shop";
import {getSelectedShop} from "../../../features/shops/selectors";
import AutomationCard from "../../../features/automations/components/AutomationCard";
import {getAutomations} from "../../../features/automations/selectors";

type Props =
  & RouteComponentProps
  & WithStyles<typeof styles>
  & {
    selectedShop: Shop;
    automations: Automation[];
  };

const Dashboard: FunctionComponent<Props> = ({ classes, selectedShop, automations }) => {
  return (
    <Fragment>
      <Button
        size="large"
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={() => navigate(`/shops/${selectedShop.id}/create-automation`)}
      >
        <FlashOnIcon className={classes.buttonIcon} /> Create automation
      </Button>

      <Grid container spacing={24}>
        {automations.map(automation => (
          <Grid item xs={12} sm={12} md={6} xl={4} key={automation.id}>
            <AutomationCard
              automation={automation}
              onEdit={() => navigate(`/shops/${selectedShop.id}/automations/${automation.id}`)}
            />
          </Grid>
        ))}
      </Grid>

      {automations.length === 0 && (
        <div className={classes.emptyState}>
          <Typography variant="h4" gutterBottom>No automation yet.</Typography>

          <Typography variant="body1" gutterBottom>
            Create an automation and it will show up here.
          </Typography>
        </div>
      )}
    </Fragment>
  );
}

const styles = (theme: Theme) => createStyles({
  button: {
    marginBottom: theme.spacing.unit * 2,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 4,
  },
});

const mapStateToProps = (state: RootState) => ({
  selectedShop: getSelectedShop(state),
  automations: getAutomations(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
