import React, {FunctionComponent, Fragment, useEffect, useState} from 'react'
import {Button, createStyles, withStyles, WithStyles} from '@material-ui/core'
import {red} from '@material-ui/core/colors'
import {Client} from '../../../../../api/definitions/types/client'
import {
  loadPaddleSDK,
  createPaddleSubscription,
  updatePaddleSubscriptionPayment,
} from '../../../../../utils/paddle'
import PlansDialog from './PlansDialog'
import CancelDialog from './CancelDialog'

type Props = WithStyles<typeof styles> & {
  client: Client | null
  createSubscription: (id: string) => void
  updateSubscription: (id: string, newPlan: string) => void
  cancelSubscription: (id: string) => void
}

const ActionButtons: FunctionComponent<Props> = ({
  classes,
  client,
  createSubscription,
  updateSubscription,
  cancelSubscription,
}) => {
  useEffect(loadPaddleSDK)

  const [planDialogOpen, setPlanDialog] = useState(false)
  const togglePlanDialog = () => setPlanDialog(!planDialogOpen)
  const [cancelDialogOpen, setCancelDialog] = useState(false)
  const toggleCancelDialog = () => setCancelDialog(!cancelDialogOpen)

  if (client === null) {
    return null
  }

  if (!client.subscription || client.subscription.status === 'deleted') {
    return (
      <Fragment>
        <Button onClick={togglePlanDialog} variant="contained" color="primary">
          Upgrade to paid plan
        </Button>

        <PlansDialog
          open={planDialogOpen}
          handleClose={togglePlanDialog}
          handleSelection={productId => {
            createPaddleSubscription(client, productId, () =>
              createSubscription(client.id),
            )
          }}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Button
        onClick={togglePlanDialog}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Change plan
      </Button>
      <PlansDialog
        open={planDialogOpen}
        disabledProduct={client.subscription.planId}
        handleClose={togglePlanDialog}
        handleSelection={selection => updateSubscription(client.id, selection)}
      />

      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={() => updatePaddleSubscriptionPayment(client)}
      >
        Update payment informations
      </Button>

      <Button
        color="primary"
        variant="contained"
        className={classes.cancelButton}
        onClick={toggleCancelDialog}
      >
        Cancel plan
      </Button>
      <CancelDialog
        open={cancelDialogOpen}
        handleClose={confirm => {
          if (confirm) {
            cancelSubscription(client.id)
          }

          toggleCancelDialog()
        }}
      />
    </Fragment>
  )
}

const styles = theme =>
  createStyles({
    cardHeader: {
      backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing.unit * 2,
    },
    cardActions: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing.unit * 2,
      },
    },
    button: {
      margin: theme.spacing.unit,
    },
    cancelButton: {
      margin: theme.spacing.unit,
      backgroundColor: red[400],
      '&:hover': {
        backgroundColor: red[800],
      },
    },
  })

export default withStyles(styles)(ActionButtons)
