import React, {FunctionComponent, useEffect} from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import {Id} from '../../../../api/definitions/types/id'
import {Field} from '../../../shared/components/forms/Field'
import {actions} from '../../actions'
import {MailchimpMergeField} from "../../../../api/definitions/types/integrations/mailchimp-merge-field";

export type Props = {
  name: string
  mailchimpId: Id
  listId: Id
  loading: boolean
  mergeFields: MailchimpMergeField[]
  loadMergeFields: typeof actions.loadMailchimpMergeFields
}

const MailchimpMergeTagField: FunctionComponent<Props> = ({
  name,
  mailchimpId,
  listId,
  loading,
  mergeFields,
  loadMergeFields,
}) => {
  useEffect(() => {
    loadMergeFields({mailchimpId, listId})
  }, [mailchimpId, listId])

  return (
    <Field name={name}>
      {({input, meta}) => (
        <FormControl
          error={meta.errors.length !== 0}
          disabled={'id' in meta.values}
          fullWidth
        >
          <InputLabel htmlFor={input.name}>Birthday Field</InputLabel>

          <Select
            value={input.value}
            onChange={input.onChange}
            inputProps={{
              name: input.name,
              id: input.name,
            }}
          >
            {mergeFields.map(mergeField => (
              <MenuItem selected={true} key={mergeField.id} value={mergeField.tag}>
                {mergeField.name} ({mergeField.tag})
              </MenuItem>
            ))}

            {mergeFields.length === 0 && loading && (
              <MenuItem disabled>Loading merge fields...</MenuItem>
            )}
            {mergeFields.length === 0 && !loading && (
              <MenuItem disabled>No merge field found</MenuItem>
            )}
          </Select>

          {meta.errors.map(error => (
            <FormHelperText key={error.message}>{error.message}</FormHelperText>
          ))}
        </FormControl>
      )}
    </Field>
  )
}

export default MailchimpMergeTagField
