import {fetchApi} from "./fetcher";
import {Token} from "./definitions/types/token";
import {Id} from "./definitions/types/id";
import {CreateToken as CreateTokenRequest} from "./definitions/requests/clients/create-token";
import {CreateToken as CreateTokenResponse} from "./definitions/responses/clients/create-token";
import {CreateClient as CreateClientRequest} from "./definitions/requests/clients/create-client";
import {CreateClient as CreateClientResponse} from "./definitions/responses/clients/create-client";
import {UpdateSubscription as UpdateSubscriptionRequest} from "./definitions/requests/clients/update-subscription";
import {UpdateSubscription as UpdateSubscriptionResponse} from "./definitions/responses/clients/update-subscription";
import {CancelSubscription as CancelSubscriptionResponse} from "./definitions/responses/clients/cancel-subscription";
import {GetClient as GetClientResponse} from "./definitions/responses/clients/get-client";
import {GetClientUsage as GetClientUsageResponse} from "./definitions/responses/clients/get-client-usage";
import {GetIntegrationCollection as GetIntegrationCollectionResponse} from "./definitions/responses/integrations/get-integration-collection";
import {GetShopCollection as GetShopCollectionResponse} from "./definitions/responses/shops/get-shop-collection";
import {CreateMailchimp as CreateMailchimpRequest} from "./definitions/requests/integrations/create-mailchimp";
import {CreateMailchimp as CreateMailchimpResponse} from "./definitions/responses/integrations/create-mailchimp";
import {GetMailchimpListCollection} from "./definitions/responses/integrations/get-mailchimp-list-collection";
import {CreateAutomation as CreateAutomationRequest} from "./definitions/requests/automations/create-automation";
import {CreateAutomation as CreateAutomationResponse} from "./definitions/responses/automations/create-automation";
import {GetAutomationCollection as GetAutomationCollectionResponse} from "./definitions/responses/automations/get-automation-collection";
import {UpdateAutomation as UpdateAutomationRequest} from "./definitions/requests/automations/update-automation";
import {UpdateAutomation as UpdateAutomationResponse} from "./definitions/responses/automations/update-automation";
import {DeleteAutomation as DeleteAutomationRequest} from "./definitions/requests/automations/delete-automation";
import {GetAutomation as GetAutomationResponse} from "./definitions/responses/automations/get-automation";
import {GetMagentoSalesRuleCollection} from "./definitions/responses/shops/get-magento-sales-rule-collection";
import {ConnectMagento as ConnectMagentoRequest} from "./definitions/requests/shops/connect-magento";
import {ConnectMagento as ConnectMagentoResponse} from "./definitions/responses/shops/connect-magento";
import {CreatePasswordRequest} from "./definitions/requests/clients/create-password-request";
import {UpdatePasswordRequest} from "./definitions/requests/clients/update-password-request";
import {GetMailchimpMergeFieldCollection} from "./definitions/responses/integrations/get-mailchimp-merge-field-collection";

const tokensUrl = '/tokens';
export const createToken = (token: Token | null) => ({ email, password }: CreateTokenRequest) =>
  fetchApi<CreateTokenResponse>(token, tokensUrl, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  })

const clientsUrl = '/clients';
export const createClient = (token: Token | null) => (data: CreateClientRequest) =>
  fetchApi<CreateClientResponse>(token, clientsUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  })

export const loadClient = (token: Token | null) => (id: Id) =>
  fetchApi<GetClientResponse>(token, `${clientsUrl}/${id}`, {
    method: 'GET',
  })

export const loadClientUsage = (token: Token | null) => (id: Id) =>
  fetchApi<GetClientUsageResponse>(token, `${clientsUrl}/${id}/usage`, {
    method: 'GET',
  })

export const updateSubscriptionPlan = (token: Token | null) => (id: Id, data: UpdateSubscriptionRequest) =>
  fetchApi<UpdateSubscriptionResponse>(token, `${clientsUrl}/${id}/subscription`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })

export const cancelSubscription = (token: Token | null) => (id: Id) =>
  fetchApi<CancelSubscriptionResponse>(token, `${clientsUrl}/${id}/subscription`, {
    method: 'DELETE',
  })

export const requestPassword = (token: Token | null) => (data: CreatePasswordRequest) =>
  fetchApi<{}>(token, `/password-requests`, {
    method: 'POST',
    body: JSON.stringify(data),
  })

export const resetPassword = (token: Token | null) => (
  clientId: string,
  code: string,
  data: UpdatePasswordRequest,
) =>
  fetchApi<{}>(token, `/clients/${clientId}/password-requests?code=${code}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })

const integrationsUrl = '/integrations';
export const loadIntegrations = (token: Token | null) => () =>
  fetchApi<GetIntegrationCollectionResponse>(token, `${integrationsUrl}`, {
    method: 'GET'
  })

export const loadMailchimpListCollection = (token: Token | null) => (mailchimpId: Id) =>
  fetchApi<GetMailchimpListCollection>(token, `${integrationsUrl}/mailchimps/${mailchimpId}/lists`, {
    method: 'GET',
  })

export const loadMailchimpMergeFieldCollection = (token: Token | null) => (mailchimpId: Id, listId: Id, type = 'birthday') =>
  fetchApi<GetMailchimpMergeFieldCollection>(token, `${integrationsUrl}/mailchimps/${mailchimpId}/lists/${listId}/merge-fields?type=${type}`, {
    method: 'GET',
  })

export const createMailchimpIntegration = (token: Token | null) => (data: CreateMailchimpRequest) =>
  fetchApi<CreateMailchimpResponse>(token, `${integrationsUrl}/mailchimps`, {
    method: 'POST',
    body: JSON.stringify(data),
  })

const shopsUrl = '/shops';
export const loadShops = (token: Token | null) => () =>
  fetchApi<GetShopCollectionResponse>(token, `${shopsUrl}`, {
    method: 'GET'
  })

export const connectMagento = (token: Token | null) => (data: ConnectMagentoRequest) =>
  fetchApi<ConnectMagentoResponse>(token, `${shopsUrl}/magentos`, {
    method: 'POST',
    body: JSON.stringify(data),
  })

export const loadMagentoSalesRuleCollection = (token: Token | null) => (shopId: Id) =>
  fetchApi<GetMagentoSalesRuleCollection>(token, `${shopsUrl}/magentos/${shopId}/sales-rules`, {
    method: 'GET'
  })

const automationsUrl = '/automations';
export const loadAutomationCollection = (token: Token | null) => () =>
  fetchApi<GetAutomationCollectionResponse>(token, `${automationsUrl}`, {
    method: 'GET'
  })

export const loadAutomation = (token: Token | null) => (id: Id) =>
  fetchApi<GetAutomationResponse>(token, `${automationsUrl}/${id}`, {
    method: 'GET'
  })

export const createAutomation = (token: Token | null) => (data: CreateAutomationRequest) =>
  fetchApi<CreateAutomationResponse>(token, automationsUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  })

export const updateAutomation = (token: Token | null) => (id: Id, data: UpdateAutomationRequest) =>
  fetchApi<UpdateAutomationResponse>(token, `${automationsUrl}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })

export const deleteAutomation = (token: Token | null) => (id: Id) =>
  fetchApi<DeleteAutomationRequest>(token, `${automationsUrl}/${id}`, {
    method: 'DELETE',
  })
