import React, {FunctionComponent, Fragment} from 'react'
import {
  Button,
  createStyles,
  Grid,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'

import {Problem} from '../../../../api/definitions/responses/problem'
import {Form} from '../../../shared/components/forms/Form'
import ConditionFields from './ConditionFields'
import CouponConfigurationFields from './CouponConfigurationFields'
import IntegrationConfigurationFields from "./IntegrationConfigurationFields";

type Props = WithStyles<typeof styles> & {
  initialValues: any
  persistAutomation: (automation: any) => void
  persistAutomationProblem: Problem | null
  persistAutomationLoading: boolean
}

const completeValues = values => ({
  ...values,
  integrationConfiguration: {
    ...values.integrationConfiguration,
    listId: values.condition.listId,
    integrationId: values.condition.integrationId,
  },
})

const AutomationForm: FunctionComponent<Props> = ({
  classes,
  initialValues,
  persistAutomation,
  persistAutomationProblem,
  persistAutomationLoading,
}) => (
  <Form
    initialValues={initialValues}
    problem={persistAutomationProblem}
    submitting={persistAutomationLoading}
    onSubmit={values => persistAutomation(completeValues(values))}
  >
    {({handleSubmit, values, submitting}) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" className={classes.paperTitle}>
                When to generate a coupon?
              </Typography>

              <ConditionFields />
            </Paper>
          </Grid>

          {values['condition.type'] && values['condition.integrationId'] && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.paperTitle}>
                  Where to send the coupon?
                </Typography>

                <IntegrationConfigurationFields />
              </Paper>
            </Grid>
          )}

          {values['condition.type'] && values['condition.integrationId'] && values['integrationConfiguration.type'] && (
            <Fragment>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.paperTitle}>
                    What coupon to generate?
                  </Typography>

                  <CouponConfigurationFields />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                >
                  {values.id ? 'Update automation' : 'Create automation'}
                </Button>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </form>
    )}
  </Form>
)

const styles = theme =>
  createStyles({
    paper: {
      padding: theme.spacing.unit * 2,
    },
    paperTitle: {
      marginBottom: theme.spacing.unit * 2,
    },
  })

export default withStyles(styles)(AutomationForm)
