import React, {FunctionComponent} from "react";
import {RouteComponentProps} from "@reach/router";
import AnonymousLayout from '../Layout';
import RequestPasswordForm from '../../../features/auth/components/RequestPasswordForm';
import Footer from "./Footer";

type Props = RouteComponentProps;

const Login: FunctionComponent<Props> = () => (
  <AnonymousLayout title="Reset my password" footer={<Footer />}>
    <RequestPasswordForm />
  </AnonymousLayout>
);

export default Login;
