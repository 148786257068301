import React from 'react';
import {connect} from "react-redux";
import {RouteComponentProps, Redirect} from '@reach/router';

import {actions as authActions} from "../../../features/auth/actions";
import {getDefaultShop, getSelectedShop, getShopsWithoutSelection} from "../../../features/shops/selectors";
import Layout from "./Layout";

const mapDispatchToProps = ({
  handleLogoutClick: authActions.logout,
});

const mapStateToProps = (state) => ({
  shops: getShopsWithoutSelection(state),
  menuShop: getSelectedShop(state) || getDefaultShop(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
