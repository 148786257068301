import React from 'react'
import {connect} from 'react-redux'

import {RootState} from '../../../root-reducer'
import {actions} from '../../actions'
import MailchimpListField from './MailchimpListField'

const mapStateToProps = (state: RootState, ownProps) => ({
  loading: state.integrations.mailchimpList.loading,
  lists: state.integrations.mailchimpList.items[ownProps.mailchimpId] || [],
})

const mapDispatchToProps = {
  loadLists: actions.loadMailchimpLists,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MailchimpListField)
