import React, {FunctionComponent} from 'react';
import {ListItemText} from "@material-ui/core";
import {WooCommerce} from "../../../../../../api/definitions/types/shops/woo-commerce";

type Props = {
  shop: WooCommerce;
}

export const WooCommerceItem: FunctionComponent<Props> = ({ shop }) => (
  <ListItemText primary="WooCommerce" secondary={shop.name} />
);
