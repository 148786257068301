import React, {FunctionComponent} from 'react'
import {
  Avatar,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {Field} from '../../../../shared/components/forms/Field'
import mailchimpLogo from '../../../../../assets/images/logo-mailchimp.png';

const defaultOption = {
  label: 'Unknown',
  value: '',
  integration: 'Unknown',
  logo:
    'https://pbs.twimg.com/profile_images/897125132702949377/GV4MymOe_400x400.jpg',
}

const options = [
  {
    label: 'To a Merge Field',
    value: 'mailchimp_merge_field',
    integration: 'Mailchimp',
    logo: mailchimpLogo,
  },
]

const getOption = value =>
  options.find(option => option.value === value) || defaultOption

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
    },
  })

const TriggerCondition: FunctionComponent<WithStyles<typeof styles>> = ({
  classes,
}) => {
  return (
    <Field name="integrationConfiguration.type">
      {({input, meta}) =>
        input.value ? (
          <List className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={getOption(input.value).logo} />
              </ListItemAvatar>
              <ListItemText
                primary={getOption(input.value).label}
                secondary={getOption(input.value).integration}
              />

              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Back"
                  disabled={'id' in meta.values}
                  onClick={() => meta.setValue(undefined)}
                >
                  <ArrowBack />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ) : (
          <Grid container spacing={8}>
            {options.map(option => (
              <Grid item xs={6} key={option.value}>
                <ListItem
                  key={option.value}
                  button
                  onClick={() => meta.setValue(option.value)}
                >
                  <ListItemAvatar>
                    <Avatar src={option.logo} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={option.label}
                    secondary={option.integration}
                  />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        )
      }
    </Field>
  )
}

export default withStyles(styles)(TriggerCondition)
