import { combineEpics } from 'redux-observable';
import {epics as authEpics} from "./auth/epics";
import {epics as shopsEpics} from "./shops/epics";
import {epics as integrationsEpics} from "./integrations/epics";
import {epics as automationsEpics} from "./automations/epics";

export default combineEpics(
  authEpics,
  shopsEpics,
  integrationsEpics,
  automationsEpics,
);
