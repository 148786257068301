import React, {FunctionComponent} from 'react'
import {RouteComponentProps, Link} from '@reach/router'
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {Form} from '../../../../shared/components/forms/Form'
import {Field} from '../../../../shared/components/forms/Field'
import {Problem} from '../../../../../api/definitions/responses/problem'
import magentoLogo from '../../../../../assets/images/logo-magento.png'

type Props = RouteComponentProps &
  WithStyles<typeof styles> & {
    problem: Problem | null
    submitting: boolean
    connectMagento: Function
  }

const ConnectMagentoForm: FunctionComponent<Props> = ({
  problem,
  submitting,
  connectMagento,
  classes,
}) => (
  <Form problem={problem} submitting={submitting} onSubmit={connectMagento}>
    {({handleSubmit, submitting}) => (
      <form className={classes.form} onSubmit={handleSubmit}>
        <img src={magentoLogo} alt="WooCommerce logo" />

        <Field name="url" defaultValue="https://">
          {({input, meta}) => (
            <FormControl
              margin="normal"
              error={meta.errors.length > 0}
              required
              fullWidth
            >
              <InputLabel htmlFor={input.name}>Shop URL</InputLabel>
              <Input
                {...input}
                placeholder="https://my-shop.com/"
                autoFocus={true}
              />

              {meta.errors.length === 0 && (
                <FormHelperText>
                  This should be the root domain of your shop, eg: https://my-shop.com/
                </FormHelperText>
              )}

              {meta.errors.map(error => (
                <FormHelperText key={error.message}>
                  {error.message}
                </FormHelperText>
              ))}
            </FormControl>
          )}
        </Field>

        <Field name="accessToken">
          {({input, meta}) => (
            <FormControl
              margin="normal"
              error={meta.errors.length > 0}
              required
              fullWidth
            >
              <InputLabel htmlFor={input.name}>Access Token</InputLabel>
              <Input {...input} placeholder="" />

              {meta.errors.map(error => (
                <FormHelperText key={error.message}>
                  {error.message}
                </FormHelperText>
              ))}

              <FormHelperText>
                <a
                  href="https://docs.personaldiscount.io/how-to/create-an-access-token-in-magento2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn how to generate an access token
                </a>
              </FormHelperText>
            </FormControl>
          )}
        </Field>

        <div className={classes.buttonGroup}>
          <Button
            component={props => <Link to="../" {...props} />}
            type="submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={submitting}
          >
            Connect Shop
          </Button>
        </div>
      </form>
    )}
  </Form>
)

const styles = theme =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      marginTop: theme.spacing.unit * 3,
    },
    button: {
      margin: theme.spacing.unit,
    },
  })

export default withStyles(styles)(ConnectMagentoForm)
