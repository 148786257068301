import React from 'react'
import {connect} from 'react-redux'
import {RootState} from '../../../root-reducer'
import {actions} from '../../actions'
import {getMailchimpIntegrations} from '../../selectors'
import MailchimpField from './MailchimpField'
import {openPopup} from '../../../../utils/popup'

const mapStateToProps = (state: RootState) => ({
  loading: state.integrations.loading || state.integrations.addingMailchimp,
  addedMailchimpIntegration: state.integrations.addedMailchimp,
  mailchimps: getMailchimpIntegrations(state),
})

const mapDispatchToProps = dispatch => ({
  addMailchimpIntegration: () => {
    const redirectUri = `${window.location.href.split('?')[0]}?popup=1`
    const urlParams = {
      response_type: 'code',
      client_id: process.env.REACT_APP_MAILCHIMP_CLIENT_ID,
      redirect_uri: redirectUri,
    }

    openPopup(
      'mailchimp-account',
      `https://login.mailchimp.com/oauth2/authorize`,
      urlParams,
    )
      .then(params =>
        dispatch(
          actions.createMailchimpIntegration({
            code: params.get('code') || '',
            redirectUri: redirectUri,
          }),
        ),
      )
      .catch(console.log)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MailchimpField)
