import {combineEpics, Epic} from 'redux-observable'
import {filter, map, switchMap} from 'rxjs/operators'
import {getType, isOfType} from 'typesafe-actions'

import {RootState} from '../root-reducer'
import {RootAction} from '../root-action'
import {actions} from './actions'
import {actions as sharedActions} from '../shared/actions'
import {actions as authActions} from '../auth/actions'
import {
  connectMagento,
  loadMagentoSalesRuleCollection,
  loadShops,
} from '../../api/clients'
import {isProblem} from '../../api/models'

const initializeShopsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(
      isOfType([
        getType(sharedActions.initializeApp),
        getType(authActions.loginSuccess),
      ]),
    ),
    filter(() => store.value.auth.token !== null),
    map(() => actions.loadShops()),
  )

const loadShopsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadShops))),
    switchMap(() => loadShops(store.value.auth.token)()),
    map(response =>
      !isProblem(response)
        ? actions.loadShopsSuccess(response)
        : actions.loadShopsFailure(response),
    ),
  )

const connectMagentoEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.connectMagento))),
    switchMap(action => connectMagento(store.value.auth.token)(action.payload)),
    map(response =>
      !isProblem(response)
        ? actions.connectMagentoSuccess(response)
        : actions.connectMagentoFailure(response),
    ),
  )

const connectMagentoSuccessEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isOfType(getType(actions.connectMagentoSuccess))),
    map(() =>
      sharedActions.showSnackbarMessage({
        message: 'Shop has been connected with success.',
        timeout: 7000,
      }),
    ),
  )

const loadMagentoSalesRulesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store,
) =>
  action$.pipe(
    filter(isOfType(getType(actions.loadMagentoSalesRules))),
    switchMap(action =>
      loadMagentoSalesRuleCollection(store.value.auth.token)(
        action.payload.shopId,
      ),
    ),
    map(response =>
      !isProblem(response)
        ? actions.loadMagentoSalesRulesSuccess(response)
        : actions.loadMagentoSalesRulesFailure(response),
    ),
  )

export const epics = combineEpics(
  initializeShopsEpic,
  loadShopsEpic,
  connectMagentoEpic,
  connectMagentoSuccessEpic,
  loadMagentoSalesRulesEpic,
)
