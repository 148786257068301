import React, {FunctionComponent} from 'react';
import {NullItem} from "./NullItem";
import {WooCommerceItem} from "./WooCommerceItem";
import {Shop} from "../../../../../../api/definitions/types/shop";
import {MagentoItem} from "./MagentoItem";

type Props = {
  shop: Shop,
};

const ShopItem: FunctionComponent<Props> = ({ shop }) => {
  switch (shop.type) {
    case 'woo_commerce':
      return <WooCommerceItem shop={shop} />;

    case 'magento':
      return <MagentoItem shop={shop} />;
  }

  return <NullItem />;
};

export default ShopItem;
