import React, {FunctionComponent} from 'react'
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {grey, red} from '@material-ui/core/colors'
import mailchimpLogo from '../../../../assets/images/logo-mailchimp.png'
import {PartialAutomation} from '../../../../api/definitions/types/automation/partial-automation'

type Props = WithStyles<typeof styles> & {
  automation: PartialAutomation
  onEdit: () => void
  onDelete: (id: string) => void
}

const AutomationCard: FunctionComponent<Props> = ({
  classes,
  automation,
  onEdit,
  onDelete,
}) => {
  return (
    <Card>
      <CardHeader
        title={automation.name}
        subheader={`${automation.numberOfCoupons} coupons delivered`}
        avatar={<Avatar src={mailchimpLogo} />}
        // action={<Switch />}
      />

      <CardActions>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={onEdit}
        >
          Edit
        </Button>

        <Button
          size="small"
          color="primary"
          variant="contained"
          className={classes.deleteButton}
          onClick={() => onDelete(automation.id)}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  )
}

const styles = () =>
  createStyles({
    mailchimpLogo: {
      padding: '10px',
      backgroundColor: grey[100],
    },
    deleteButton: {
      backgroundColor: red[400],
      '&:hover': {
        backgroundColor: red[800],
      },
    },
  })

export default withStyles(styles)(AutomationCard)
