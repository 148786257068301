import {connect} from "react-redux";
import {compose, pipe, tap, prop} from "ramda";
import {actions} from '../../actions';
import {RootState} from '../../../root-reducer';
import RequestPasswordForm from "./RequestPasswordForm";

const mapSubmitToAction = submitEvent => actions.submitRequestPasswordForm({
  email: submitEvent.email.value,
});


const mapStateToProps = (state : RootState) => ({
  isSubmitting: state.auth.requestPasswordForm.submitting,
  problem: state.auth.requestPasswordForm.problem,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: pipe(
    tap<any>(event => event.preventDefault()),
    compose(dispatch, mapSubmitToAction, prop('target'))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestPasswordForm);
