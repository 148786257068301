import * as queryString from 'query-string';
import React, {FunctionComponent} from 'react';
import {RouteComponentProps, Link} from '@reach/router'
import {Button, createStyles, Paper, Typography, withStyles, WithStyles} from "@material-ui/core";

const styles = theme => createStyles({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(650 + 300)]: {
      width: 650,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing.unit * 2,
  }
});

type Props = RouteComponentProps<{ mergeTag: string }> & WithStyles<typeof styles>;

const CreateWooCommerce: FunctionComponent<Props> = ({ classes, location }) => {
  const queryParams = queryString.parse(location.search);
  const mergeTag = queryParams['mergeTag'] || '';

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          Automation enabled!
        </Typography>

        <Typography variant="body1" className={classes.content}>
          Personal Discount will generate unique coupon codes for your subscribers.
          However, in order to send the coupons you need to set up an automated campaign in Mailchimp and include a merge tag <b>*|{mergeTag}|*</b> in your email template.
        </Typography>

        <Typography variant="body1" className={classes.content} gutterBottom>
          You can watch the following video to learn how to send the generated coupons using Mailchimp.
        </Typography>

        <iframe
          title="Youtube setup video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/IQ2x7jclbxY?start=131"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Paper>

      <Button
        component={props => <Link to="../../" {...props} />}
        color="primary"
        variant="contained"
        className={classes.button}
      >
        Continue to Dashboard
      </Button>
    </div>
  );
}

export default withStyles(styles)(CreateWooCommerce);
