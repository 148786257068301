import React, {FunctionComponent} from 'react';
import {ListItemText} from "@material-ui/core";
import {Magento} from "../../../../../../api/definitions/types/shops/magento";

type Props = {
  shop: Magento;
}

export const MagentoItem: FunctionComponent<Props> = ({ shop }) => (
  <ListItemText primary="Magento" secondary={shop.name} />
);
