import React, {FunctionComponent} from "react";
import {RouteComponentProps} from "@reach/router";
import AnonymousLayout from '../Layout';
import LoginForm from '../../../features/auth/components/LoginForm';
import LoginFooter from "./LoginFooter";

type Props = RouteComponentProps;

const Login: FunctionComponent<Props> = () => (
  <AnonymousLayout title="Login" footer={<LoginFooter />}>
    <LoginForm />
  </AnonymousLayout>
);

export default Login;
