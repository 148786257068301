import {Problem} from "./definitions/responses/problem";

export function isProblem(value: any): value is Problem
{
  if (typeof value !== 'object') {
    return false;
  }

  return 'title' in value && 'detail' in value;
}
