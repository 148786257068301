import {createSelector} from 'reselect'
import {RootState} from '../root-reducer'

const shops = (state: RootState) => state.shops.items
const selectedShopId = (state: RootState) => state.shops.selection

export const getSelectedShop = createSelector(
  shops,
  selectedShopId,
  (shops, selectedShopId) => {
    if (selectedShopId === null || shops.length < 1) {
      return null
    }

    const shop = shops.find(shop => shop.id === selectedShopId)
    if (shop) {
      return shop
    }

    return [...shops].shift() || null
  },
)

export const getDefaultShop = createSelector(
  shops,
  shops => [...shops].pop() || null,
)

export const getShopsWithoutSelection = createSelector(
  shops,
  getSelectedShop,
  getDefaultShop,
  (shops, selectedShop, defaultShop) => {
    const shop = selectedShop || defaultShop
    if (shop === null) {
      return shops
    }

    return shops.filter(item => item.id !== shop.id)
  },
)
