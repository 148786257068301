import React, {FunctionComponent} from 'react';
import {RouteComponentProps} from "@reach/router";
import {createStyles, withStyles, WithStyles} from "@material-ui/core";
import AutomationForm from "../../../features/automations/components/AutomationForm";

const styles = theme => createStyles({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(650 + 300)]: {
      width: 650,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
});

type Props = RouteComponentProps & WithStyles<typeof styles>;

const CreateWooCommerce: FunctionComponent<Props> = ({ classes }) => (
  <div className={classes.layout}>
    <AutomationForm automationId={null} />
  </div>
);

export default withStyles(styles)(CreateWooCommerce);
