import {combineReducers} from 'redux'
import {getType} from 'typesafe-actions'
import {actions} from './actions'
import {RootAction} from '../root-action'
import {Id} from '../../api/definitions/types/id'
import {Shop} from '../../api/definitions/types/shop'
import {MagentoSalesRule} from '../../api/definitions/types/shops/magento-sales-rule'
import {Problem} from '../../api/definitions/responses/problem'

export type State = {
  items: Shop[]
  selection: Id | null
  loading: boolean
  magentoSalesRules: {
    loading: boolean
    items: MagentoSalesRule[]
  }
  magentoForm: {
    submitting: boolean
    problem: Problem | null
  }
}

export const reducer = combineReducers<State, RootAction>({
  items: (state = [], action) => {
    switch (action.type) {
      case getType(actions.loadShopsSuccess):
        return action.payload

      case getType(actions.connectMagentoSuccess):
        return [...state, action.payload]
    }

    return state
  },
  selection: (state = null, action) =>
    action.type === getType(actions.selectShop) ? action.payload : state,
  loading: (state = false, action) => {
    switch (action.type) {
      case getType(actions.loadShops):
        return true

      case getType(actions.loadShopsSuccess):
      case getType(actions.loadShopsFailure):
        return false
    }

    return state
  },
  magentoSalesRules: (state = {loading: false, items: []}, action) => {
    switch (action.type) {
      case getType(actions.loadMagentoSalesRules):
        return {...state, loading: true}

      case getType(actions.loadMagentoSalesRulesSuccess):
        return {...state, loading: false, items: action.payload}

      case getType(actions.loadMagentoSalesRulesFailure):
        return {...state, loading: false}
    }

    return state
  },
  magentoForm: (state = {submitting: false, problem: null}, action) => {
    switch (action.type) {
      case getType(actions.connectMagento):
        return {submitting: true, problem: null}

      case getType(actions.connectMagentoSuccess):
        return {submitting: false, problem: null}

      case getType(actions.connectMagentoFailure):
        return {submitting: false, problem: action.payload}
    }

    return state
  },
})
