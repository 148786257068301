import React, {FunctionComponent} from 'react'
import {
  Button,
  CircularProgress,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'

type Props = WithStyles<typeof styles> & {
  loading: boolean
  addMailchimpIntegration: () => void
}

const ConnectMailchimpButton: FunctionComponent<Props> = ({
  addMailchimpIntegration,
  loading,
  classes,
}) => {
  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={addMailchimpIntegration}
    >
      {loading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        'Connect to Mailchimp'
      )}
    </Button>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: theme.palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })

export default withStyles(styles)(ConnectMailchimpButton)
