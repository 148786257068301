import React, {FunctionComponent} from "react";
import {RouteComponentProps} from "@reach/router";
import AnonymousLayout from '../Layout';
import Footer from "./Footer";

type Props = RouteComponentProps;

const Login: FunctionComponent<Props> = () => (
  <AnonymousLayout title="Email sent with success" footer={<Footer />}>
    <p>We have sent you an email with a link to reset your password.</p>
  </AnonymousLayout>
);

export default Login;
