import {ActionType, createStandardAction} from 'typesafe-actions'
import {Problem} from '../../api/definitions/responses/problem'
import {GetAutomationCollection as GetAutomationCollectionResponse} from '../../api/definitions/responses/automations/get-automation-collection'
import {CreateAutomation as CreateAutomationRequest} from '../../api/definitions/requests/automations/create-automation'
import {CreateAutomation as CreateAutomationResponse} from '../../api/definitions/responses/automations/create-automation'
import {UpdateAutomation as UpdateAutomationRequest} from '../../api/definitions/requests/automations/update-automation'
import {UpdateAutomation as UpdateAutomationResponse} from '../../api/definitions/responses/automations/update-automation'
import {GetAutomation as GetAutomationResponse} from '../../api/definitions/responses/automations/get-automation'
import {Id} from '../../api/definitions/types/id'

const LOAD_AUTOMATION_COLLECTION = 'automations/LOAD_AUTOMATION_COLLECTION'
const LOAD_AUTOMATION_COLLECTION_SUCCESS =
  'automations/LOAD_AUTOMATION_COLLECTION_SUCCESS'
const LOAD_AUTOMATION_COLLECTION_FAILURE =
  'automations/LOAD_AUTOMATION_COLLECTION_FAILURE'

const LOAD_AUTOMATION = 'automations/LOAD_AUTOMATION'
const LOAD_AUTOMATION_SUCCESS = 'automations/LOAD_AUTOMATION_SUCCESS'
const LOAD_AUTOMATION_FAILURE = 'automations/LOAD_AUTOMATION_FAILURE'

const CREATE_AUTOMATION = 'automations/CREATE_AUTOMATION'
const CREATE_AUTOMATION_SUCCESS = 'automations/CREATE_AUTOMATION_SUCCESS'
const CREATE_AUTOMATION_FAILURE = 'automations/CREATE_AUTOMATION_FAILURE'

const UPDATE_AUTOMATION = 'automations/UPDATE_AUTOMATION'
const UPDATE_AUTOMATION_SUCCESS = 'automations/UPDATE_AUTOMATION_SUCCESS'
const UPDATE_AUTOMATION_FAILURE = 'automations/UPDATE_AUTOMATION_FAILURE'

const DELETE_AUTOMATION = 'automations/DELETE_AUTOMATION'
const DELETE_AUTOMATION_SUCCESS = 'automations/DELETE_AUTOMATION_SUCCESS'
const DELETE_AUTOMATION_FAILURE = 'automations/DELETE_AUTOMATION_FAILURE'

export const actions = {
  loadAutomationCollection: createStandardAction(LOAD_AUTOMATION_COLLECTION)(),
  loadAutomationCollectionSuccess: createStandardAction(
    LOAD_AUTOMATION_COLLECTION_SUCCESS,
  )<GetAutomationCollectionResponse>(),
  loadAutomationCollectionFailure: createStandardAction(
    LOAD_AUTOMATION_COLLECTION_FAILURE,
  )<Problem>(),

  loadAutomation: createStandardAction(LOAD_AUTOMATION)<{id: Id}>(),
  loadAutomationSuccess: createStandardAction(LOAD_AUTOMATION_SUCCESS)<{
    id: Id
    response: GetAutomationResponse
  }>(),
  loadAutomationFailure: createStandardAction(LOAD_AUTOMATION_FAILURE)<{
    id: Id
    response: Problem
  }>(),

  createAutomation: createStandardAction(CREATE_AUTOMATION)<
    CreateAutomationRequest
  >(),
  createAutomationSuccess: createStandardAction(CREATE_AUTOMATION_SUCCESS)<
    CreateAutomationResponse
  >(),
  createAutomationFailure: createStandardAction(CREATE_AUTOMATION_FAILURE)<
    Problem
  >(),

  updateAutomation: createStandardAction(UPDATE_AUTOMATION)<{
    id: Id
    data: UpdateAutomationRequest
  }>(),
  updateAutomationSuccess: createStandardAction(UPDATE_AUTOMATION_SUCCESS)<{
    id: Id
    response: UpdateAutomationResponse
  }>(),
  updateAutomationFailure: createStandardAction(UPDATE_AUTOMATION_FAILURE)<{
    id: Id
    response: Problem
  }>(),

  deleteAutomation: createStandardAction(DELETE_AUTOMATION)<{id: Id}>(),
  deleteAutomationSuccess: createStandardAction(DELETE_AUTOMATION_SUCCESS)<{
    id: Id
  }>(),
  deleteAutomationFailure: createStandardAction(DELETE_AUTOMATION_FAILURE)<{
    id: Id
  }>(),
}

export type Action = ActionType<typeof actions>
