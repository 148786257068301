import React, {FunctionComponent, useEffect, useState} from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import {Field} from '../../../shared/components/forms/Field'
import {MagentoSalesRule} from '../../../../api/definitions/types/shops/magento-sales-rule'

export type Props = {
  name: string
  loading: boolean
  rules: MagentoSalesRule[]
  shopId: string
  loadRules: (shopId) => void
}

const MagentoSalesRulesField: FunctionComponent<Props> = ({
  name,
  rules,
  loading,
  shopId,
  loadRules,
}) => {
  useEffect(() => {
    loadRules(shopId)
  }, [shopId])

  return (
    <Field name={name} defaultValue={0}>
      {({input, meta}) => (
        <FormControl error={meta.errors.length !== 0} fullWidth>
          <InputLabel htmlFor={input.name}>Cart price rule</InputLabel>

          <Select
            value={input.value}
            onChange={input.onChange}
            inputProps={{
              name: input.name,
              id: input.name,
            }}
          >
            {rules.map(list => (
              <MenuItem selected={true} key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}

            {rules.length === 0 && loading && (
              <MenuItem selected={true} value={input.value as any} disabled>
                Loading rules...
              </MenuItem>
            )}

            {rules.length === 0 && !loading && (
              <MenuItem disabled>No sales rules found</MenuItem>
            )}
          </Select>

          {meta.errors.length === 0 && (
            <FormHelperText error={true}>
              Make sure to have the <b>Use Auto Generation</b> option checked in your rule configuration.
            </FormHelperText>
          )}

          {meta.errors.map(error => (
            <FormHelperText key={error.message}>{error.message}</FormHelperText>
          ))}
        </FormControl>
      )}
    </Field>
  )
}

export default MagentoSalesRulesField
