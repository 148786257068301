import React from 'react'
import {connect} from 'react-redux'
import {actions} from '../../actions'
import AutomationCard from './AutomationCard'

const mapDispatchToProps = dispatch => ({
  onDelete: (id: string) => dispatch(actions.deleteAutomation({id})),
})

export default connect(
  null,
  mapDispatchToProps,
)(AutomationCard)
