import {combineReducers} from 'redux'
import {getType} from 'typesafe-actions'
import {actions, Action} from './actions'
import {Client} from '../../api/definitions/types/client'
import {Token} from '../../api/definitions/types/token'
import {Problem} from '../../api/definitions/responses/problem'
import {Usage} from '../../api/definitions/types/client/usage'

export type State = {
  token: Token | null
  client: {
    loading: boolean
    item: Client | null
  }
  subscription: {
    creating: boolean
    updating: boolean
    canceling: boolean
  }
  usage: {
    loading: boolean
    item: Usage | null
  }
  loginForm: {
    submitting: boolean
    problem: Problem | null
  }
  registerForm: {
    submitting: boolean
    problem: Problem | null
  }
  requestPasswordForm: {
    submitting: boolean
    problem: Problem | null
  }
  resetPasswordForm: {
    submitting: boolean
    problem: Problem | null
  }
}

export const reducer = combineReducers<State, Action>({
  token: (state = null, action) => {
    switch (action.type) {
      case getType(actions.loginSuccess):
        return action.payload.token

      case getType(actions.loadClientFailure):
      case getType(actions.logout):
        return null

      default:
        return state
    }
  },
  client: (state = {loading: false, item: null}, action) => {
    switch (action.type) {
      case getType(actions.loadClient):
        return {...state, loading: true}

      case getType(actions.loginSuccess):
        return {...state, loading: false, item: action.payload.client}

      case getType(actions.createSubscriptionSuccess):
      case getType(actions.loadClientSuccess):
        return {...state, loading: false, item: action.payload}

      case getType(actions.loadClientFailure):
        return {...state, loading: false}

      case getType(actions.updateSubscriptionSuccess):
      case getType(actions.cancelSubscriptionSuccess):
        return {...state, item: action.payload.data}

      default:
        return state
    }
  },
  subscription: (
    state = {creating: false, updating: false, canceling: false},
    action,
  ) => {
    switch (action.type) {
      case getType(actions.createSubscription):
        return {...state, creating: true}

      case getType(actions.createSubscriptionFailure):
      case getType(actions.createSubscriptionSuccess):
        return {...state, creating: false}

      case getType(actions.updateSubscription):
        return {...state, updating: true}

      case getType(actions.updateSubscriptionFailure):
      case getType(actions.updateSubscriptionSuccess):
        return {...state, updating: false}

      case getType(actions.cancelSubscription):
        return {...state, canceling: true}

      case getType(actions.cancelSubscriptionFailure):
      case getType(actions.cancelSubscriptionSuccess):
        return {...state, canceling: false}

      default:
        return state
    }
  },
  usage: (state = {loading: false, item: null}, action) => {
    switch (action.type) {
      case getType(actions.loadClientUsage):
        return {...state, loading: true}

      case getType(actions.loadClientUsageSuccess):
        return {loading: false, item: action.payload.data}

      case getType(actions.loadClientUsageFailure):
        return {...state, loading: false}

      default:
        return state
    }
  },
  loginForm: (state = {submitting: false, problem: null}, action) => {
    switch (action.type) {
      case getType(actions.submitLoginForm):
        return {
          ...state,
          submitting: true,
        }

      case getType(actions.loginSuccess):
        return {
          submitting: false,
          problem: null,
        }

      case getType(actions.loginFailure):
        return {
          submitting: false,
          problem: action.payload.problem,
        }

      default:
        return state
    }
  },
  registerForm: (state = {submitting: false, problem: null}, action) => {
    switch (action.type) {
      case getType(actions.submitRegisterForm):
        return {
          ...state,
          submitting: true,
        }

      case getType(actions.registerSuccess):
        return {
          submitting: false,
          problem: null,
        }

      case getType(actions.registerFailure):
        return {
          submitting: false,
          problem: action.payload.problem,
        }

      default:
        return state
    }
  },
  requestPasswordForm: (state = {submitting: false, problem: null}, action) => {
    switch (action.type) {
      case getType(actions.submitRequestPasswordForm):
        return {
          ...state,
          submitting: true,
        }

      case getType(actions.requestPasswordSuccess):
        return {
          submitting: false,
          problem: null,
        }

      case getType(actions.requestPasswordFailure):
        return {
          submitting: false,
          problem: action.payload.problem,
        }

      default:
        return state
    }
  },
  resetPasswordForm: (state = {submitting: false, problem: null}, action) => {
    switch (action.type) {
      case getType(actions.submitResetPasswordForm):
        return {
          ...state,
          submitting: true,
        }

      case getType(actions.resetPasswordSuccess):
        return {
          submitting: false,
          problem: null,
        }

      case getType(actions.resetPasswordFailure):
        return {
          submitting: false,
          problem: action.payload.problem,
        }

      default:
        return state
    }
  },
})
