import React, {FunctionComponent} from "react";
import {RouteComponentProps} from "@reach/router";
import RegisterForm from '../../../features/auth/components/RegisterForm';
import AnonymousLayout from '../Layout';
import RegisterFooter from "./RegisterFooter";

type Props = RouteComponentProps;

const Register: FunctionComponent<Props> = () => (
  <AnonymousLayout title="Register" footer={<RegisterFooter />}>
    <RegisterForm />
  </AnonymousLayout>
);

export default Register;
