import React, {FunctionComponent} from 'react'
import {Grid} from '@material-ui/core'
import MailchimpMergeTagFields from './MailchimpMergeTag/MailchimpMergeTagFields'
import {ConditionField} from '../../../../shared/components/forms/ConditionField'
import IntegrationConfigurationTypeSelect from './IntegrationConfigurationTypeSelect'

const IntegrationConfigurationFields: FunctionComponent = () => (
  <Grid container spacing={24}>
    <Grid item xs={12}>
      <IntegrationConfigurationTypeSelect />
    </Grid>

    <ConditionField when="integrationConfiguration.type" is="mailchimp_merge_field">
      <MailchimpMergeTagFields />
    </ConditionField>
  </Grid>
)

export default IntegrationConfigurationFields
