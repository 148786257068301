import React, {FunctionComponent} from 'react'
import {Router, Redirect} from '@reach/router'
import Login from './Login'
import Register from './Register'
import ResetPassword from './ResetPassword'
import RequestPassword from './RequestPassword'
import RequestPasswordSuccess from './RequestPasswordSuccess'

const App: FunctionComponent<{}> = () => (
  <Router>
    <Login path="login" />
    <Register path="register" />
    <ResetPassword path="reset-password" />
    <RequestPassword path="request-password" />
    <RequestPasswordSuccess path="request-password-success" />

    <Redirect from="*" to="login" noThrow />
  </Router>
)

export default App
