import React, {FunctionComponent, Fragment} from 'react'
import {format, addDays} from 'date-fns'
import {ListItemText} from '@material-ui/core'
import {Client} from '../../../../api/definitions/types/client'

type Props = {
  client: Client
}

const productName = (paddleProduct: string) => {
  switch (paddleProduct) {
    case '550174':
      return 'Pro'
    case '550175':
      return 'Growing'
    case '550176':
      return 'Starter'
  }

  return 'Custom'
}

const formattedDate = (date: Date) => format(date, 'MMMM d, y')

const ListItemPlan: FunctionComponent<Props> = ({client}) => {
  if (!client.subscription) {
    return (
      <ListItemText
        primary={`Plan: Free`}
        secondary={
          <Fragment>
            No expiration date
          </Fragment>
        }
      />
    )
  }

  if (client.subscription.status === 'deleted') {
    return (
      <ListItemText
        primary={`Plan: ${productName(client.subscription.planId)}`}
        secondary={
          <Fragment>
            Expires on{' '}
            <i>
              {formattedDate(
                new Date(client.subscription.expireAt || new Date()),
              )}
            </i>
          </Fragment>
        }
      />
    )
  }

  return (
    <ListItemText
      primary={`Plan: ${productName(client.subscription.planId)}`}
      secondary={`Renews on ${formattedDate(
        new Date(client.subscription.nextBill || new Date()),
      )}`}
    />
  )
}

export default ListItemPlan
