import {connect} from "react-redux";
import {compose, pipe, tap, prop} from "ramda";
import {actions} from '../../actions';
import {RootState} from '../../../root-reducer';
import LoginForm from "./LoginForm";

const mapSubmitToAction = submitEvent => actions.submitLoginForm({
  email: submitEvent.email.value,
  password: submitEvent.password.value,
  rememberMe: submitEvent.rememberMe.checked,
});


const mapStateToProps = (state : RootState) => ({
  isSubmitting: state.auth.loginForm.submitting || state.auth.client.loading,
  problem: state.auth.loginForm.problem,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: pipe(
    tap<any>(event => event.preventDefault()),
    compose(dispatch, mapSubmitToAction, prop('target'))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
