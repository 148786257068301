import React, {FunctionComponent} from 'react'
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  SnackbarContent,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {Error as ErrorIcon} from '@material-ui/icons'
import {Problem} from '../../../../api/definitions/responses/problem'

type Props = WithStyles<typeof styles> & {
  handleSubmit: any
  isSubmitting: boolean
  problem: Problem | null
}

const LoginForm: FunctionComponent<Props> = ({
  handleSubmit,
  isSubmitting,
  problem,
  classes,
}) => (
  <form className={classes.form} onSubmit={handleSubmit}>
    {problem && (
      <SnackbarContent
        message={
          <span className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {problem.detail}
          </span>
        }
      />
    )}

    <FormControl margin="normal" required fullWidth>
      <InputLabel htmlFor="email">Email Address</InputLabel>
      <Input id="email" name="email" autoComplete="email" autoFocus />
    </FormControl>

    <FormControl margin="normal" required fullWidth>
      <InputLabel htmlFor="password">Password</InputLabel>
      <Input
        name="password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
    </FormControl>

    <FormControlLabel
      control={<Checkbox value="remember" color="primary" name="rememberMe" />}
      label="Remember me"
    />

    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={isSubmitting}
    >
      Sign in
    </Button>
  </form>
)

const styles = theme =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    icon: {
      fontSize: 25,
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    snackbar: {
      backgroundColor: theme.palette.error.dark,
    },
  })

export default withStyles(styles)(LoginForm)
