import React, {Fragment, FunctionComponent, useState} from "react";
import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemText,
  WithStyles,
  withStyles
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import ShopItem from "./ShopItem";
import ListItemLink from "../../../../../features/shared/components/links/ListItemLink";
import {Shop} from "../../../../../api/definitions/types/shop";

type Props = WithStyles<typeof styles> & {
  shops: Shop[];
  selectedShop: Shop;
  handleNavigationClick: any;
};

const HeaderList: FunctionComponent<Props> = ({
  classes,
  shops,
  selectedShop,
  handleNavigationClick,
}) => {
  const [headerListOpen, setHeaderList] = useState(false);
  const toggleHeaderList = () => setHeaderList(!headerListOpen);

  const navigationClickHandler = () => {
    toggleHeaderList();
    handleNavigationClick();
  };

  return (
    <Fragment>
      <ListItem button onClick={() => toggleHeaderList()}>
        <ShopItem shop={selectedShop} />
        <ExpandMore />
      </ListItem>

      <Collapse in={headerListOpen} timeout="auto" unmountOnExit>
        <List component="span" disablePadding onClick={navigationClickHandler}>
          {shops.map(shop => (
            <ListItemLink
              key={shop.id}
              className={classes.nested}
              to={`/shops/${shop.id}`}
            >
              <ShopItem shop={shop} />
            </ListItemLink>
          ))}

          <ListItemLink to={`/connect-shop`} className={classes.nested}>
            <ListItemText primary="Connect another shop" />
          </ListItemLink>
        </List>
      </Collapse>
    </Fragment>
  )
}

const styles = (theme) => createStyles({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

export default withStyles(styles)(HeaderList);
