import {ActionType, createStandardAction} from 'typesafe-actions'
import {Problem} from '../../api/definitions/responses/problem'
import {CreateToken as CreateTokenRequest} from '../../api/definitions/requests/clients/create-token'
import {CreateToken as CreateTokenResponse} from '../../api/definitions/responses/clients/create-token'
import {CreateClient as CreateClientRequest} from '../../api/definitions/requests/clients/create-client'
import {CreateClient as CreateClientResponse} from '../../api/definitions/responses/clients/create-client'
import {GetClient as GetClientResponse} from '../../api/definitions/responses/clients/get-client'
import {GetClientUsage as GetClientUsageResponse} from '../../api/definitions/responses/clients/get-client-usage'
import {UpdateSubscription as UpdateSubscriptionRequest} from '../../api/definitions/requests/clients/update-subscription'
import {UpdateSubscription as UpdateSubscriptionResponse} from '../../api/definitions/responses/clients/update-subscription'
import {CancelSubscription as CancelSubscriptionResponse} from '../../api/definitions/responses/clients/cancel-subscription'
import {CreatePasswordRequest} from '../../api/definitions/requests/clients/create-password-request'
import {UpdatePasswordRequest} from '../../api/definitions/requests/clients/update-password-request'

const SUBMIT_LOGIN_FORM = 'auth/SUBMIT_LOGIN_FORM'
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
const LOGIN_FAILURE = 'auth/LOGIN_FAILURE'
const LOGOUT = 'auth/LOGOUT'

const SUBMIT_REGISTER_FORM = 'auth/SUBMIT_REGISTER_FORM'
const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS'
const REGISTER_FAILURE = 'auth/REGISTER_FAILURE'

const SUBMIT_REQUEST_PASSWORD_FORM = 'auth/SUBMIT_REQUEST_PASSWORD_FORM'
const REQUEST_PASSWORD_SUCCESS = 'auth/REQUEST_PASSWORD_SUCCESS'
const REQUEST_PASSWORD_FAILURE = 'auth/REQUEST_PASSWORD_FAILURE'

const SUBMIT_RESET_PASSWORD_FORM = 'auth/SUBMIT_RESET_PASSWORD_FORM'
const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE'

const LOAD_CLIENT = 'auth/LOAD_CLIENT'
const LOAD_CLIENT_SUCCESS = 'auth/LOAD_CLIENT_SUCCESS'
const LOAD_CLIENT_FAILURE = 'auth/LOAD_CLIENT_FAILURE'

const LOAD_CLIENT_USAGE = 'auth/LOAD_CLIENT_USAGE'
const LOAD_CLIENT_USAGE_SUCCESS = 'auth/LOAD_CLIENT_USAGE_SUCCESS'
const LOAD_CLIENT_USAGE_FAILURE = 'auth/LOAD_CLIENT_USAGE_FAILURE'

const CREATE_SUBSCRIPTION = 'auth/CREATE_SUBSCRIPTION'
const CREATE_SUBSCRIPTION_SUCCESS = 'auth/CREATE_SUBSCRIPTION_SUCCESS'
const CREATE_SUBSCRIPTION_FAILURE = 'auth/CREATE_SUBSCRIPTION_FAILURE'

const UPDATE_SUBSCRIPTION = 'auth/UPDATE_SUBSCRIPTION'
const UPDATE_SUBSCRIPTION_SUCCESS = 'auth/UPDATE_SUBSCRIPTION_SUCCESS'
const UPDATE_SUBSCRIPTION_FAILURE = 'auth/UPDATE_SUBSCRIPTION_FAILURE'

const CANCEL_SUBSCRIPTION = 'auth/CANCEL_SUBSCRIPTION'
const CANCEL_SUBSCRIPTION_SUCCESS = 'auth/CANCEL_SUBSCRIPTION_SUCCESS'
const CANCEL_SUBSCRIPTION_FAILURE = 'auth/CANCEL_SUBSCRIPTION_FAILURE'

export const actions = {
  submitLoginForm: createStandardAction(SUBMIT_LOGIN_FORM)<
    CreateTokenRequest & {
      rememberMe: boolean
    }
  >(),
  loginSuccess: createStandardAction(LOGIN_SUCCESS)<
    CreateTokenResponse & {
      rememberMe: boolean
    }
  >(),
  loginFailure: createStandardAction(LOGIN_FAILURE)<{problem: Problem}>(),
  logout: createStandardAction(LOGOUT)(),

  submitRegisterForm: createStandardAction(SUBMIT_REGISTER_FORM)<
    CreateClientRequest
  >(),
  registerSuccess: createStandardAction(REGISTER_SUCCESS)<
    CreateClientResponse
  >(),
  registerFailure: createStandardAction(REGISTER_FAILURE)<{problem: Problem}>(),

  submitRequestPasswordForm: createStandardAction(SUBMIT_REQUEST_PASSWORD_FORM)<
    CreatePasswordRequest
  >(),
  requestPasswordSuccess: createStandardAction(REQUEST_PASSWORD_SUCCESS)<{}>(),
  requestPasswordFailure: createStandardAction(REQUEST_PASSWORD_FAILURE)<{
    problem: Problem
  }>(),

  submitResetPasswordForm: createStandardAction(SUBMIT_RESET_PASSWORD_FORM)<{
    clientId: string
    code: string
    data: UpdatePasswordRequest
  }>(),
  resetPasswordSuccess: createStandardAction(RESET_PASSWORD_SUCCESS)<{}>(),
  resetPasswordFailure: createStandardAction(RESET_PASSWORD_FAILURE)<{
    problem: Problem
  }>(),

  loadClient: createStandardAction(LOAD_CLIENT)<{id: string}>(),
  loadClientSuccess: createStandardAction(LOAD_CLIENT_SUCCESS)<
    GetClientResponse
  >(),
  loadClientFailure: createStandardAction(LOAD_CLIENT_FAILURE)(),

  loadClientUsage: createStandardAction(LOAD_CLIENT_USAGE)<{id: string}>(),
  loadClientUsageSuccess: createStandardAction(LOAD_CLIENT_USAGE_SUCCESS)<{
    id: string
    data: GetClientUsageResponse
  }>(),
  loadClientUsageFailure: createStandardAction(LOAD_CLIENT_USAGE_FAILURE)<{
    id: string
    data: Problem
  }>(),

  createSubscription: createStandardAction(CREATE_SUBSCRIPTION)<{id: string}>(),
  createSubscriptionSuccess: createStandardAction(CREATE_SUBSCRIPTION_SUCCESS)<
    GetClientResponse
  >(),
  createSubscriptionFailure: createStandardAction(CREATE_SUBSCRIPTION_FAILURE)<
    Problem
  >(),

  updateSubscription: createStandardAction(UPDATE_SUBSCRIPTION)<{
    id: string
    data: UpdateSubscriptionRequest
  }>(),
  updateSubscriptionSuccess: createStandardAction(UPDATE_SUBSCRIPTION_SUCCESS)<{
    id: string
    data: UpdateSubscriptionResponse
  }>(),
  updateSubscriptionFailure: createStandardAction(UPDATE_SUBSCRIPTION_FAILURE)<{
    id: string
    data: Problem
  }>(),

  cancelSubscription: createStandardAction(CANCEL_SUBSCRIPTION)<{id: string}>(),
  cancelSubscriptionSuccess: createStandardAction(CANCEL_SUBSCRIPTION_SUCCESS)<{
    id: string
    data: CancelSubscriptionResponse
  }>(),
  cancelSubscriptionFailure: createStandardAction(CANCEL_SUBSCRIPTION_FAILURE)<{
    id: string
    data: Problem
  }>(),
}

export type Action = ActionType<typeof actions>
