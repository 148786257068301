import React, {FunctionComponent, Fragment, useEffect} from 'react'
import {RouteComponentProps, navigate} from '@reach/router'
import {
  createStyles,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {
  AccountCircle as AccountCircleIcon,
  LocalOffer as LocalOfferIcon,
  StoreMallDirectory as StoreMallDirectoryIcon,
} from '@material-ui/icons'
import {Client} from '../../../../api/definitions/types/client'
import SubscriptionButtons from './ActionButtons'
import ListItemPlan from './ListItemPlan'
import {Usage} from '../../../../api/definitions/types/client/usage'

type Props = RouteComponentProps &
  WithStyles<typeof styles> & {
    client: Client | null
    usage: Usage | null
    loading: boolean
    loadClientUsage: (id: string) => void
  }

const progressValue = (value, maxValue) => (value * 100) / maxValue

const SubscriptionSettings: FunctionComponent<Props> = ({
  classes,
  client,
  usage,
  loading,
  loadClientUsage,
}) => {
  useEffect(() => {
    if (client === null) {
      return
    }

    loadClientUsage(client.id)
  }, client)

  return (
    <Paper className={classes.paper}>
      <Fragment>
        <Typography variant="h6">Manage Subscription</Typography>
        <Typography color="textSecondary" className={classes.title}>
          Your subscription can be changed at any time, your bill will be
          automatically prorated to reflect the proper amount.
        </Typography>

        {client === null ||
          usage === null ||
          (loading && (
            <LinearProgress color="secondary" className={classes.loader} />
          ))}

        {client !== null && usage !== null && !loading && (
          <Fragment>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemPlan client={client} />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <StoreMallDirectoryIcon />
                </ListItemIcon>

                <div className={classes.listItemProgress}>
                  <Typography
                    variant={'body1'}
                    component="span"
                    className={classes.listItemProgressTitle}
                  >
                    Shop limit: {usage.numberOfShops.toLocaleString()}/
                    {client.shopsLimit.toLocaleString()}
                  </Typography>

                  <LinearProgress
                    variant="determinate"
                    value={progressValue(
                      usage.numberOfShops,
                      client.shopsLimit,
                    )}
                  />
                </div>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LocalOfferIcon />
                </ListItemIcon>

                <div className={classes.listItemProgress}>
                  <Typography
                    variant={'body1'}
                    component="span"
                    className={classes.listItemProgressTitle}
                  >
                    Coupon per month: {usage.couponsUsage.toLocaleString()}/
                    {client.couponsLimit.toLocaleString()}
                  </Typography>

                  <LinearProgress
                    variant="determinate"
                    value={progressValue(
                      usage.couponsUsage,
                      client.couponsLimit,
                    )}
                  />
                </div>
              </ListItem>
            </List>

            <Divider className={classes.divider} />
            <SubscriptionButtons client={client} />
          </Fragment>
        )}
      </Fragment>
    </Paper>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      padding: theme.spacing.unit * 2,
    },
    listItemProgress: {
      flex: '1 1 auto',
      minWidth: 0,
      padding: '0 16px',
      '&:first-child': {
        paddingLeft: 0,
      },
    },
    listItemProgressTitle: {
      marginBottom: '6px',
    },
    divider: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit * 2,
    },
    loader: {
      marginTop: theme.spacing.unit * 4,
    },
  })

export default withStyles(styles)(SubscriptionSettings)
