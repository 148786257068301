import React, {FunctionComponent, Fragment} from 'react'
import {FormControl, FormHelperText, Grid, Input, InputLabel} from '@material-ui/core'

import {Field} from '../../../../../shared/components/forms/Field'

const MailchimpMergeTagFields: FunctionComponent<{}> = () => (
  <Fragment>
    <Grid item xs={12} md={12}>
      <Field name="integrationConfiguration.mergeFieldTag" defaultValue="COUPON_01">
        {({input, meta}) => (
          <FormControl error={meta.errors.length !== 0} disabled={'id' in meta.values} fullWidth>
            <InputLabel htmlFor={input.name}>Merge Field Tag</InputLabel>
            <Input {...input} />

            {input.value && meta.errors.length === 0 && (
              <FormHelperText>
                Usage in Mailchimp email template: <b>*|{input.value}|*</b>
              </FormHelperText>
            )}
            {meta.errors.map(error => (
              <FormHelperText key={error.message}>
                {error.message}
              </FormHelperText>
            ))}
          </FormControl>
        )}
      </Field>
    </Grid>
  </Fragment>
)

export default MailchimpMergeTagFields
