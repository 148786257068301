import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {RootState} from '../../../root-reducer'
import {actions} from '../../actions'
import AutomationForm from './AutomationForm'
import {Id} from '../../../../api/definitions/types/id'
import {getSelectedShop} from '../../../shops/selectors'
import {LinearProgress} from '@material-ui/core'
import LoadingDelay from 'react-loading-delay'
import {Shop} from '../../../../api/definitions/types/shop'

type OwnProps = {
  automationId: Id | null
}

const defaultValues = (shop: Shop) => ({
  shopId: shop.id,
  condition: {
  },
  integrationConfiguration: {
    type: 'mailchimp_merge_field',
  },
  couponConfiguration: {
    type: shop.type,
  },
})

const mapStateToProps = (state: RootState) => ({
  selectedShop: getSelectedShop(state),
  automation: state.automations.automationForm.automation,
  persistAutomationProblem: state.automations.automationForm.problem,
  persistAutomationLoading: state.automations.automationForm.loading,
})

const mapDispatchToProps = (dispatch, ownProps: OwnProps) => ({
  loadAutomation: (id: Id) => dispatch(actions.loadAutomation({id})),
  persistAutomation: values =>
    ownProps.automationId === null
      ? dispatch(actions.createAutomation(values))
      : dispatch(
          actions.updateAutomation({id: ownProps.automationId, data: values}),
        ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const {automationId, loadAutomation, automation, selectedShop} = props as any

  useEffect(() => {
    if (automationId) {
      loadAutomation(automationId)
    }
  }, [loadAutomation, automationId])

  if (automationId === null) {
    return (
      <AutomationForm {...props} initialValues={defaultValues(selectedShop)} />
    )
  }

  if (automation === null) {
    return (
      <LoadingDelay check={true} delay={750}>
        {({isDelaying}) => (!isDelaying ? <LinearProgress /> : null)}
      </LoadingDelay>
    )
  }

  return <AutomationForm {...props} initialValues={automation} />
})
