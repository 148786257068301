import React, {FunctionComponent, ReactElement} from 'react'
import {
  WithStyles,
  withStyles,
  Typography,
  Paper,
  CssBaseline,
  createStyles,
  Theme,
} from '@material-ui/core'
import logo from '../../assets/images/logo.svg'

type Props = WithStyles<typeof styles> & {
  title: string
  footer: ReactElement<any>
}

const Layout: FunctionComponent<Props> = ({
  title,
  footer,
  children,
  classes,
}) => (
  <main className={classes.main}>
    <CssBaseline />

    <Paper className={classes.paper}>
      <figure className={classes.avatar}>
        <img src={logo} alt="logo" height={128} />
      </figure>

      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      {children}
    </Paper>

    <Typography color="textSecondary" align="center" className={classes.footer}>
      {footer}
    </Typography>
  </main>
)

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`,
    },
    avatar: {
      marginTop: theme.spacing.unit * -8,
    },
    footer: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
  })

export default withStyles(styles)(Layout)
