import React, {FunctionComponent, Fragment} from 'react';
import {Link} from '@reach/router';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  link: {
    color: theme.palette.primary.main,
  },
});

type FooterProps = WithStyles<typeof styles>;

const LoginFooter: FunctionComponent<FooterProps> = ({ classes }) => (
  <Fragment>
    Forgot your password? <Link to="/request-password" className={classes.link}>Click here</Link>
    <br />
    Don't have an account yet? <Link to="/register" className={classes.link}>Register here!</Link>
  </Fragment>
);

export default withStyles(styles)(LoginFooter);
