import {Token} from "./definitions/types/token";
import {Problem} from "./definitions/responses/problem";

export const fetchApi = <T>(token: Token | null, resource, options): Promise<T | Problem> =>
  fetch(`${process.env.REACT_APP_API_URL}${resource}`, {
    ...options,
    headers: getHeaders(token),
  })
  .then(response => mapResponseToData<T>(response))
  .catch(() => ({ title: 'Connection error', detail: 'Unable to connect to the API.', errors: [] }))
;

const mapResponseToData = <T>(response: Response): Promise<T | Problem> => {
  if (response.type === 'error') {
    return Promise.resolve({ title: 'Connection error', detail: 'Unable to connect to the API.', errors: [] });
  }

  if (response.status === 204) {
    return Promise.resolve({} as T);
  }

  return response.json();
}

const getHeaders = (token: Token | null) => token !== null
  ? ({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` })
  : ({ 'Content-Type': 'application/json' });
