import React, {FunctionComponent} from 'react'
import {Grid} from '@material-ui/core'
import MailchimpNewSubscriberFields from './MailchimpNewSubscriberFields/MailchimpNewSubscriberFields'
import MailchimpSubscriberBirthdayFields from './MailchimpSubscriberBirthdayFields/MailchimpSubscriberBirthdayFields'
import {ConditionField} from '../../../../shared/components/forms/ConditionField'
import ConditionTypeSelect from './ConditionTypeSelect'

const ConditionFields: FunctionComponent = () => (
  <Grid container spacing={24}>
    <Grid item xs={12}>
      <ConditionTypeSelect />
    </Grid>

    <ConditionField when="condition.type" is="mailchimp_new_subscriber">
      <MailchimpNewSubscriberFields />
    </ConditionField>

    <ConditionField when="condition.type" is="mailchimp_subscriber_birthday">
      <MailchimpSubscriberBirthdayFields />
    </ConditionField>
  </Grid>
)

export default ConditionFields
