import React, {FunctionComponent, Fragment} from "react";
import {createStyles, Divider, List, ListItemIcon, ListItemText, withStyles, WithStyles} from "@material-ui/core";
import { Dashboard as DashboardIcon } from "@material-ui/icons";
import ListItemLink from "../../../../features/shared/components/links/ListItemLink";
import HeaderList from "./HeaderList/HeaderList";
import {Shop} from "../../../../api/definitions/types/shop";

const styles = theme => createStyles({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
})

type Props = WithStyles<typeof styles> & {
  shops: Shop[];
  selectedShop: Shop;
  handleNavigationClick: any;
};

const SideBarList: FunctionComponent<Props> = ({
  shops,
  selectedShop,
  handleNavigationClick,
}) => {
  return (
    <Fragment>
      <HeaderList shops={shops} selectedShop={selectedShop} handleNavigationClick={handleNavigationClick} />

      <Divider />

      <List onClick={handleNavigationClick}>
        <ListItemLink to={`/shops/${selectedShop.id}`}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemLink>
      </List>
    </Fragment>
  );
}

export default withStyles(styles)(SideBarList);
