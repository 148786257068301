import React, {FunctionComponent} from 'react';
import {
  AppBar, Avatar,
  createStyles,
  CssBaseline,
  Toolbar,
  WithStyles,
  withStyles
} from "@material-ui/core";
import logo from '../../../assets/images/logo.svg';

export type Props = WithStyles<typeof styles>;

const Layout: FunctionComponent<Props> = ({
  children,
  classes,
}) => {
  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed">
        <Toolbar>
          <Avatar src={logo} className={classes.logo} />
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

const styles = theme => createStyles({
  root: {
    display: 'flex',
  },
  logo: {
    boxShadow: `0px 0px 8px 0px ${theme.palette.primary.light}`,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(Layout);
