import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { reducer as sharedReducer } from './shared/reducer';
import { reducer as authReducer } from './auth/reducer';
import { reducer as shopsReducer } from './shops/reducer';
import { reducer as integrationsReducer } from './integrations/reducer';
import { reducer as automationsReducer } from "./automations/reducer";

const rootReducer = combineReducers({
  shared: sharedReducer,
  auth: authReducer,
  shops: shopsReducer,
  integrations: integrationsReducer,
  automations: automationsReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
