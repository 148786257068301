import {ActionType, createStandardAction} from 'typesafe-actions'
import {Problem} from '../../api/definitions/responses/problem'
import {GetIntegrationCollection} from '../../api/definitions/responses/integrations/get-integration-collection'
import {CreateMailchimp as CreateMailchimpRequest} from '../../api/definitions/requests/integrations/create-mailchimp'
import {CreateMailchimp as CreateMailchimpResponse} from '../../api/definitions/responses/integrations/create-mailchimp'
import {GetMailchimpListCollection as GetMailchimpListCollectionResponse} from '../../api/definitions/responses/integrations/get-mailchimp-list-collection'
import {GetMailchimpMergeFieldCollection as GetMailchimpMergeFieldCollectionResponse} from '../../api/definitions/responses/integrations/get-mailchimp-merge-field-collection'
import {Id} from '../../api/definitions/types/id'

const LOAD_INTEGRATIONS = 'integrations/LOAD_INTEGRATIONS'
const LOAD_INTEGRATIONS_SUCCESS = 'integrations/LOAD_INTEGRATIONS_SUCCESS'
const LOAD_INTEGRATIONS_FAILURE = 'integrations/LOAD_INTEGRATIONS_FAILURE'

const CREATE_MAILCHIMP_AUTHORIZATION =
  'integrations/CREATE_MAILCHIMP_AUTHORIZATION'
const CREATE_MAILCHIMP_INTEGRATION = 'integrations/CREATE_MAILCHIMP_INTEGRATION'
const CREATE_MAILCHIMP_INTEGRATION_SUCCESS =
  'integrations/CREATE_MAILCHIMP_INTEGRATION_SUCCESS'
const CREATE_MAILCHIMP_INTEGRATION_FAILURE =
  'integrations/CREATE_MAILCHIMP_INTEGRATION_FAILURE'

const LOAD_MAILCHIMP_LISTS = 'integrations/LOAD_MAILCHIMP_LISTS'
const LOAD_MAILCHIMP_LISTS_SUCCESS = 'integrations/LOAD_MAILCHIMP_LISTS_SUCCESS'
const LOAD_MAILCHIMP_LISTS_FAILURE = 'integrations/LOAD_MAILCHIMP_LISTS_FAILURE'

const LOAD_MAILCHIMP_MERGE_FIELDS = 'integrations/LOAD_MAILCHIMP_MERGE_FIELDS'
const LOAD_MAILCHIMP_MERGE_FIELDS_SUCCESS =
  'integrations/LOAD_MAILCHIMP_MERGE_FIELDS_SUCCESS'
const LOAD_MAILCHIMP_MERGE_FIELDS_FAILURE =
  'integrations/LOAD_MAILCHIMP_MERGE_FIELDS_FAILURE'

export const actions = {
  loadIntegrations: createStandardAction(LOAD_INTEGRATIONS)(),
  loadIntegrationsSuccess: createStandardAction(LOAD_INTEGRATIONS_SUCCESS)<
    GetIntegrationCollection
  >(),
  loadIntegrationsFailure: createStandardAction(LOAD_INTEGRATIONS_FAILURE)<
    Problem
  >(),

  createMailchimpAuthorization: createStandardAction(
    CREATE_MAILCHIMP_AUTHORIZATION,
  )(),
  createMailchimpIntegration: createStandardAction(
    CREATE_MAILCHIMP_INTEGRATION,
  )<CreateMailchimpRequest>(),
  createMailchimpIntegrationSuccess: createStandardAction(
    CREATE_MAILCHIMP_INTEGRATION_SUCCESS,
  )<CreateMailchimpResponse>(),
  createMailchimpIntegrationFailure: createStandardAction(
    CREATE_MAILCHIMP_INTEGRATION_FAILURE,
  )<Problem>(),

  loadMailchimpLists: createStandardAction(LOAD_MAILCHIMP_LISTS)<{
    mailchimpId: Id
  }>(),
  loadMailchimpListsSuccess: createStandardAction(
    LOAD_MAILCHIMP_LISTS_SUCCESS,
  )<{
    mailchimpId: Id
    lists: GetMailchimpListCollectionResponse
  }>(),
  loadMailchimpListsFailure: createStandardAction(
    LOAD_MAILCHIMP_LISTS_FAILURE,
  )<{
    mailchimpId: Id
    problem: Problem
  }>(),

  loadMailchimpMergeFields: createStandardAction(LOAD_MAILCHIMP_MERGE_FIELDS)<{
    mailchimpId: Id
    listId: Id
  }>(),
  loadMailchimpMergeFieldsSuccess: createStandardAction(
    LOAD_MAILCHIMP_MERGE_FIELDS_SUCCESS,
  )<{
    mailchimpId: Id
    listId: Id
    mergeFields: GetMailchimpMergeFieldCollectionResponse
  }>(),
  loadMailchimpMergeFieldsFailure: createStandardAction(
    LOAD_MAILCHIMP_MERGE_FIELDS_FAILURE,
  )<{
    mailchimpId: Id
    listId: Id
    problem: Problem
  }>(),
}

export type Action = ActionType<typeof actions>
