import React from 'react'
import {connect} from 'react-redux'

import {RootState} from '../../../root-reducer'
import {actions} from '../../actions'
import MailchimpMergeTagsField from './MailchimpMergeTagField'

const mapStateToProps = (state: RootState, ownProps) => ({
  loading: state.integrations.mailchimpMergeFields.loading,
  mergeFields: state.integrations.mailchimpMergeFields.items[ownProps.listId] || [],
})

const mapDispatchToProps = {
  loadMergeFields: actions.loadMailchimpMergeFields,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MailchimpMergeTagsField)
