import React, {FunctionComponent, Fragment, useEffect, useContext} from 'react'
import {
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import {Mailchimp} from '../../../../api/definitions/types/integrations/mailchimp'
import ConnectMailchimpButton from './ConnectMailchimpButton'
import {Field} from '../../../shared/components/forms/Field'
import {FormContext} from '../../../shared/components/forms/Form'

export type Props = {
  name: string
  loading: boolean
  mailchimps: Mailchimp[]
  addMailchimpIntegration: () => void
  addedMailchimpIntegration: string | null
}

const MailchimpField: FunctionComponent<Props> = ({
  name,
  loading,
  mailchimps,
  addMailchimpIntegration,
  addedMailchimpIntegration,
}) => {
  const form = useContext(FormContext)

  // update field after new integration added
  useEffect(() => {
    if (addedMailchimpIntegration !== null) {
      form.setValue(name, addedMailchimpIntegration)
    }
  }, [addedMailchimpIntegration])

  // trigger action when "New account" is selected (because MenuItem onClick doesn't work)
  useEffect(() => {
    if (form.values[name] === 'new-account') {
      addMailchimpIntegration()
    }
  }, [form.values[name]])

  return (
    <Field
      name={name}
      defaultValue={mailchimps.reduce((_, mailchimp) => mailchimp.id, '')}
    >
      {({input, meta}) => (
        <FormControl
          error={meta.errors.length !== 0}
          disabled={'id' in meta.values}
          fullWidth
        >
          {mailchimps.length === 0 && (
            <ConnectMailchimpButton
              loading={loading}
              addMailchimpIntegration={addMailchimpIntegration}
            />
          )}

          {mailchimps.length !== 0 && (
            <Fragment>
              <InputLabel htmlFor={input.name}>Mailchimp Account</InputLabel>

              <Select
                value={input.value}
                onChange={input.onChange}
                inputProps={{name: input.name, id: input.name}}
              >
                {loading && <MenuItem>Loading...</MenuItem>}

                {!loading &&
                  mailchimps.map((mailchimp, index) => (
                    <MenuItem
                      key={mailchimp.id}
                      value={mailchimp.id}
                      divider={mailchimps.length === index + 1}
                    >
                      {mailchimp.name}
                    </MenuItem>
                  ))}

                {!loading && (
                  <MenuItem value="new-account">
                    <i>New account</i>
                  </MenuItem>
                )}
              </Select>

              {meta.errors.map(error => (
                <FormHelperText key={error.message}>
                  {error.message}
                </FormHelperText>
              ))}
            </Fragment>
          )}
        </FormControl>
      )}
    </Field>
  )
}

export default MailchimpField
