import React, {FunctionComponent, useState} from 'react';
import {
  AppBar,
  createStyles,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  WithStyles,
  withStyles
} from "@material-ui/core";
import {navigate} from "@reach/router";
import {
  Menu as MenuIcon,
  Help as HelpIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";

import {Shop} from "../../../api/definitions/types/shop";
import SideBarList from "./SideBarList/SideBarList";

export type Props = WithStyles<typeof styles> & {
  shops: Shop[];
  menuShop: Shop | null;
  handleLogoutClick: any;
};

const Layout: FunctionComponent<Props> = ({
  shops,
  menuShop,
  handleLogoutClick,
  children,
  classes,
}) => {
  const [sideBarOpen, setSideBar] = useState(false);
  const toggleSideBar = () => setSideBar(!sideBarOpen);

  if (menuShop === null) {
    return null;
  }

  const Link = props => <a href="https://docs.personaldiscount.io/" target="_blank" {...props} />;

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggleSideBar}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.grow} />

          <Tooltip title="Documentation" enterDelay={300}>
            <IconButton component={Link} color="inherit">
              <HelpIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Billing" enterDelay={300}>
            <IconButton onClick={() => navigate('/settings')} color="inherit">
              <SettingsIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Log out" enterDelay={300}>
            <IconButton onClick={handleLogoutClick} color="inherit">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={sideBarOpen}
            onClose={toggleSideBar}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <SideBarList
              shops={shops}
              selectedShop={menuShop}
              handleNavigationClick={() => toggleSideBar()}
            />
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open={true}>
            <SideBarList
              shops={shops}
              selectedShop={menuShop}
              handleNavigationClick={() => {}}
            />
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

const drawerWidth = 300;
const styles = theme => createStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flex: '1 1 auto',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(Layout);
