import React, {FunctionComponent, ReactNode, FormEvent, useState} from 'react'
import * as setValue from 'set-value'
import {Problem} from '../../../../api/definitions/responses/problem'

export type FieldValue = string | number | boolean | undefined
type Fields = {[name: string]: FieldValue}

const prefixObjectKeys = (object: object, prefix: string) =>
  Object.keys(object).reduce(
    (prefixedObject, key) => ({
      ...prefixedObject,
      [prefix + '.' + key]: object[key],
    }),
    {},
  )

const flattenObject = (values: any): Fields =>
  Object.keys(values).reduce((flatObject, key) => {
    if (!values.hasOwnProperty(key)) {
      return flatObject
    }

    // check if value is an object but not an array.
    if (typeof values[key] === 'object' && !Array.isArray (values[key]) && values[key] !== null) {
      return {
        ...flatObject,
        ...prefixObjectKeys(flattenObject(values[key]), key),
      }
    }

    // if it's an array, we assume it contains only scalre values.
    return {...flatObject, [key]: values[key]}
  }, {})

const unflattenObject = (values: Fields) =>
  Object.keys(values)
    .filter(key => values[key] !== null)
    .reduce(
      (unflattenedObject, key) => setValue(unflattenedObject, key, values[key]),
      {},
    )

type ChildrenProps = {
  values: any
  submitting: boolean
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
}

type Props = {
  initialValues?: object
  problem: Problem | null
  submitting: boolean
  onSubmit: (values: object) => void
  children: (props: ChildrenProps) => ReactNode
}

export const FormContext = React.createContext({
  touched: new Set<string>(),
  values: {} as Fields,
  problem: null as Problem | null,
  submitting: false,
  setValue: (name: string, value: FieldValue) => {},
})

export const Form: FunctionComponent<Props> = props => {
  const [values, setValues] = useState(flattenObject(props.initialValues || {}))
  const [touched, setTouched] = useState(new Set<string>());

  const setValue = (name: string, value: FieldValue) => {
    setValues({...values, [name]: value})
    setTouched(new Set([...touched, name]))
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    props.onSubmit(unflattenObject(values))
  }

  return (
    <FormContext.Provider
      value={{
        touched: touched,
        values,
        setValue,
        problem: props.problem,
        submitting: props.submitting,
      }}
    >
      {props.children({
        values: values,
        submitting: props.submitting,
        handleSubmit,
      })}
    </FormContext.Provider>
  )
}
